import { useEffect, useState } from "react";
import { ProductionLineData } from ".";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BACKEND_PRODUCTION_LINES, PUT } from "../constants";
import { sessionWrapper } from "../dal/sessionWarpper";
import { postData } from "../dal/fetchRequest";
import useStore from "../store";

type UpdateProductionLinesFormProps = {
  data: ProductionLineData;
  update: boolean;
  setUpdate: Function;
  setReload: Function;
  setDelete: Function;
};

type UpdateProductionLinesInputs = {
  id: number;
  company: string;
  production_line_user_group_ID: string;
  production_line_description: string;
  ValmetID: string | null;
  MillID: string | null;
  Production_line_ID: string | null;
  FlowControlID: string | null;
  AFRY_Mill_No: string | null;
  Default_Mechanical_FL: string | null;
  Default_Automation_FL: string | null;
  Default_Electrical_FL: string | null;
  Default_Instrumenation_FL: string | null;
};

type UpdateData = {
  id: number;
  company: string;
  production_line_user_group_ID: string;
  production_line_description: string;
  ValmetID: string | null;
  MillID: string | null;
  Production_line_ID: string | null;
  FlowControlID: string | null;
  AFRY_Mill_No: string | null;
  Default_Mechanical_FL: string | null;
  Default_Automation_FL: string | null;
  Default_Electrical_FL: string | null;
  Default_Instrumenation_FL: string | null;
  organization_id: number;
};

export const UpdateProductionLinesForm = ({
  data,
  update,
  setUpdate,
  setReload,
  setDelete,
}: UpdateProductionLinesFormProps) => {
  const { t } = useTranslation();
  const {
    id,
    company,
    production_line_user_group_ID,
    production_line_description,
    ValmetID,
    MillID,
    Production_line_ID,
    FlowControlID,
    AFRY_Mill_No,
    Default_Mechanical_FL,
    Default_Automation_FL,
    Default_Electrical_FL,
    Default_Instrumenation_FL,
    organization_id,
  } = data;
  const [disabled, setDisabled] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_PRODUCTION_LINES}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const setIdAndName = useStore((state) => state.setIdAndName);
  const methods = useForm<UpdateProductionLinesInputs>({
    defaultValues: {
      company,
      production_line_user_group_ID,
      production_line_description,
      ValmetID,
      MillID,
      Production_line_ID,
      FlowControlID,
      AFRY_Mill_No,
      Default_Mechanical_FL,
      Default_Automation_FL,
      Default_Electrical_FL,
      Default_Instrumenation_FL,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (update) {
      setValue("production_line_description", production_line_description);
      setValue("ValmetID", ValmetID);
      setValue("MillID", MillID);
      setValue("Production_line_ID", Production_line_ID);
      setValue("FlowControlID", FlowControlID);
      setValue("AFRY_Mill_No", AFRY_Mill_No);
      setValue("Default_Mechanical_FL", Default_Mechanical_FL);
      setValue("Default_Automation_FL", Default_Automation_FL);
      setValue("Default_Electrical_FL", Default_Electrical_FL);
      setValue("Default_Instrumenation_FL", Default_Instrumenation_FL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const onSubmit: SubmitHandler<UpdateProductionLinesInputs> = async (data) => {
    console.log(data);
    setDisabled(true);

    const updateProductionLinesData: UpdateData = {
      id,
      company: data.company,
      production_line_user_group_ID: data.production_line_user_group_ID,
      production_line_description: data.production_line_description,
      ValmetID: data.ValmetID || null,
      MillID: data.MillID || null,
      Production_line_ID: data.Production_line_ID || null,
      FlowControlID: data.FlowControlID || null,
      AFRY_Mill_No: data.AFRY_Mill_No || null,
      Default_Mechanical_FL: data.Default_Mechanical_FL || null,
      Default_Automation_FL: data.Default_Automation_FL || null,
      Default_Electrical_FL: data.Default_Electrical_FL || null,
      Default_Instrumenation_FL: data.Default_Instrumenation_FL || null,
      organization_id,
    };

    console.log({ updateProductionLinesData });

    const response: any = await sessionWrapper(
      postData,
      PUT,
      url,
      updateProductionLinesData
    );
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    reset();
    setDisabled(false);
  };

  return (
    <FormProvider {...methods}>
      <form id="update-production-line-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label className="label">{t("Company")}</label>
          <div className="control">
            {/* READ ONLY */}
            <input
              className="input"
              type="text"
              defaultValue={company}
              disabled={true}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Production line user group ID")}</label>
          <div className="control">
            {/* READ ONLY */}
            <input
              className="input"
              type="text"
              defaultValue={production_line_user_group_ID}
              disabled={true}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Production line description")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={production_line_description}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add Production line description")}
              {...register("production_line_description", { required: true })}
            />
            {errors.production_line_description && (
              <p className="has-text-danger">{t("This field is required")}</p>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label">{t("ValmetID")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={ValmetID || ""}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add ValmetID")}
              {...register("ValmetID")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("MillID")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={MillID || ""}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add MillID")}
              {...register("MillID")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Production line ID")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={Production_line_ID || ""}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add Production line ID")}
              {...register("Production_line_ID")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("FlowControlID")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={FlowControlID || ""}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add FlowControlID")}
              {...register("FlowControlID")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("AFRY Mill No")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={AFRY_Mill_No || ""}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add AFRY Mill No")}
              {...register("AFRY_Mill_No")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Default Mechanical FL")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={Default_Mechanical_FL || ""}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add Default Mechanical FL")}
              {...register("Default_Mechanical_FL")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Default Automation FL")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={Default_Automation_FL || ""}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add Default Automation FL")}
              {...register("Default_Automation_FL")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Default Electrical FL")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={Default_Electrical_FL || ""}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add Default Electrical FL")}
              {...register("Default_Electrical_FL")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Default Instrumenation FL")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={Default_Instrumenation_FL || ""}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add Default Instrumenation FL")}
              {...register("Default_Instrumenation_FL")}
            />
          </div>
        </div>

        <div
          className={
            update
              ? "field is-grouped is-grouped-right"
              : "field is-grouped is-grouped-right is-hidden"
          }
        >
          <div className="control">
            <button
              className="button"
              type="reset"
              onClick={() => {
                reset();
                setDisabled(false);
                setUpdate(false);
              }}
            >
              {t("Cancel")}
            </button>
          </div>
          <div className="control">
            <button
              className="button is-primary"
              type="submit"
              disabled={disabled}
            >
              {t("Save changes")}
            </button>
          </div>
        </div>

        <div
          className={
            update
              ? "field is-grouped is-grouped-right is-hidden"
              : "field is-grouped is-grouped-right"
          }
        >
          <div className="control">
            <button
              className="button is-primary"
              type="button"
              disabled={disabled}
              onClick={() => {
                setDelete(true);
                setIdAndName(id, production_line_user_group_ID);
              }}
            >
              {t("Delete")}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
