import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { CognitoJwtVerifier } from "aws-jwt-verify";

const userPoolData = {
  UserPoolId: process.env.REACT_APP_VHUB_ADMIN_POOL_ID as string,
  ClientId: process.env.REACT_APP_VHUB_ADMIN_POOL_CLIENT_ID as string,
};

export const userPool = new CognitoUserPool(userPoolData);

export const signup = async (email: string, name: string, password: string) => {
  return await new Promise((resolve, reject) => {
    const attributeList: CognitoUserAttribute[] = [];

    var nameAttribute = new CognitoUserAttribute({
      Name: "name",
      Value: name,
    });

    attributeList.push(nameAttribute);

    userPool.signUp(email, password, attributeList, [], (err, data) => {
      if (err) {
        console.log("Failed to register", err.message);
        reject(err);
      }
      console.log("Account created successfully", data);
      resolve(data);
    });
  });
};

export const authenticate = async (Username: string, Password: string) => {
  return await new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username,
      Pool: userPool,
    });
    const authDetails = new AuthenticationDetails({
      Username,
      Password,
    });

    console.log({ userPool });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("Login success:", data);
        resolve(data);
      },
      onFailure: (err) => {
        console.log("Failure", err.message);
        reject(err);
      },
      newPasswordRequired(data) {
        console.log("New password required", data);
        resolve(data);
      },
    });
  });
};

export const getSession = async () => {
  return await new Promise<CognitoUserSession>((resolve, reject) => {
    const user = userPool.getCurrentUser();
    if (user) {
      user.getSession((err: Error | null, session: CognitoUserSession) => {
        if (err) {
          console.log("Error getting session", err.message);
          reject(err);
        }
        console.log("Session retrieved", session);
        resolve(session);
      });
    } else {
      console.log("No user found");
      reject("No user found");
    }
  });
};

export const validateToken = async (tokenToValidate: string) => {
  const verifier = CognitoJwtVerifier.create({
    userPoolId: userPool.getUserPoolId(),
    tokenUse: "id",
    clientId: userPool.getClientId(),
  });

  try {
    const payload = await verifier.verify(tokenToValidate);
    console.log("Token is valid.", payload);
    return true;
  } catch {
    console.log("Token not valid any more!");
    return false;
  }
};

export const logout = () => {
  const user = userPool.getCurrentUser();
  if (user) {
    console.log("Logging out", user);
    user.signOut();
  } else {
    console.log("No user to logout");
  }
};
