/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";
import { logout } from "../aws/cognito";
import { useNavigate } from "react-router-dom";
import {
  FRONTEND_APPLICATIONS,
  FRONTEND_COMPANIES,
  FRONTEND_TEST_VERSION,
  FRONTEND_PRODUCTION_LINES,
  FRONTEND_ROOT,
  FRONTEND_USERS,
} from "../constants";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useStore from "../store";

interface Environment {
  env: "development" | "production" | "test";
  REACT_APP_VHUB_ADMIN_POOL_ID: string | undefined;
  REACT_APP_VHUB_ADMIN_POOL_CLIENT_ID: string | undefined;
  REACT_APP_BACKEND: string | undefined;
  [key: string]: string | undefined;
}

const handleLogout = (
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
  logout: Function,
  navigate: Function
) => {
  event.preventDefault();
  logout();
  navigate(FRONTEND_ROOT);
};

const isActive = (currentPath: string, pathname: string) => {
  if (currentPath === FRONTEND_COMPANIES && pathname === FRONTEND_COMPANIES) {
    return "button is-primary";
  } else if (
    currentPath === FRONTEND_APPLICATIONS &&
    pathname === FRONTEND_APPLICATIONS
  ) {
    return "button is-primary";
  } else if (
    currentPath === FRONTEND_PRODUCTION_LINES &&
    pathname === FRONTEND_PRODUCTION_LINES
  ) {
    return "button is-primary";
  } else if (currentPath === FRONTEND_USERS && pathname === FRONTEND_USERS) {
    return "button is-primary";
  } else {
    return "button";
  }
};

const menuList = (t: Function, navigate: Function, location: any) => (
  <ul className="menu-list">
    <li className="py-1">
      <a
        className={isActive(FRONTEND_COMPANIES, location.pathname)}
        onClick={() => navigate(FRONTEND_COMPANIES)}
      >
        {t("Companies")}
      </a>
    </li>
    <li className="py-1">
      <a
        className={isActive(FRONTEND_APPLICATIONS, location.pathname)}
        onClick={() => navigate(FRONTEND_APPLICATIONS)}
      >
        {t("Applications")}
      </a>
    </li>
    <li className="py-1">
      <a
        className={isActive(FRONTEND_PRODUCTION_LINES, location.pathname)}
        onClick={() => navigate(FRONTEND_PRODUCTION_LINES)}
      >
        {t("Production Lines")}
      </a>
    </li>
    <li className="py-1">
      <a
        className={isActive(FRONTEND_USERS, location.pathname)}
        onClick={() => navigate(FRONTEND_USERS)}
      >
        {t("Users")}
      </a>
    </li>
  </ul>
);

const logoutButton = (t: Function, logout: Function, navigate: Function) => (
  <ul className="menu-list">
    <li className="py-1">
      <a
        className="button"
        onClick={(event) => handleLogout(event, logout, navigate)}
      >
        {t("Logout")}
      </a>
    </li>
  </ul>
);

export const getEnvironment = (
  key?: string
): Environment | string | undefined => {
  const environment: Environment = {
    env: process.env.NODE_ENV,
    REACT_APP_VHUB_ADMIN_POOL_ID: process.env.REACT_APP_VHUB_ADMIN_POOL_ID,
    REACT_APP_VHUB_ADMIN_POOL_CLIENT_ID:
      process.env.REACT_APP_VHUB_ADMIN_POOL_CLIENT_ID,
    REACT_APP_BACKEND: process.env.REACT_APP_BACKEND,
  };
  if (key) {
    return environment[key];
  }
  return environment;
};

export const ClientInfo = () => {
  return (
    <>
      <p className="has-text-centered	is-size-7 pt-3">
        env: {getEnvironment("env") as string}
      </p>
      <p className="has-text-centered	is-size-7">
        version: {FRONTEND_TEST_VERSION}
      </p>
    </>
  );
};

const LeftNav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <aside className="menu p-3 is-hidden-mobile">
      <p className="menu-label">{t("Administration")}</p>
      {menuList(t, navigate, location)}
      <div className="py-6"></div>
      <p className="menu-label">{t("User")}</p>
      {logoutButton(t, logout, navigate)}
      <ClientInfo />
    </aside>
  );
};

const TopNav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [expanded, setExpanded] = useState(false);
  return (
    <nav
      className="navbar is-hidden-tablet"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a
          role="button"
          className={expanded ? "navbar-burger is-active" : "navbar-burger"}
          aria-label="menu"
          aria-expanded="false"
          onClick={() => setExpanded(!expanded)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        className={expanded ? "navbar-menu is-active" : "navbar-menu"}
        id="navMenu"
      >
        <aside className="menu p-3">
          <p className="menu-label">{t("Administration")}</p>
          {menuList(t, navigate, location)}
          <div className="py-3"></div>
          <p className="menu-label">{t("User")}</p>
          {logoutButton(t, logout, navigate)}
        </aside>
        <ClientInfo />
      </div>
    </nav>
  );
};

export const ErrorNotification = () => {
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const error = useStore((state) => state.error);
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    setVisibility(error !== null);
  }, [error]);

  return (
    <div
      className={
        visibility
          ? "notification is-danger is-sticky-top"
          : "notification is-danger is-sticky-top is-hidden"
      }
    >
      <button
        className="delete"
        onClick={() => {
          setVisibility(!visibility);
          resetGlobalError();
        }}
      ></button>
      {error}
    </div>
  );
};

export const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="columns">
      <div className="column is-one-fifth">
        <LeftNav />
      </div>
      <div className="column">
        <TopNav />
        <ErrorNotification />
        {children}
      </div>
    </div>
  );
};
