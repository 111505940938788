/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStore from "../store";

type PagingProps = {
  limit20: number;
  limit50: number;
  limit100: number;
  limit: number;
  setLimit: Function;
  offset: number;
  setOffset: Function;
  total: number;
  setPagingParams: Function;
  resetTableSorting: Function;
};

export const Paging: React.FC<PagingProps> = ({
  limit20,
  limit50,
  limit100,
  limit,
  setLimit,
  offset,
  setOffset,
  total,
  setPagingParams,
  resetTableSorting,
}) => {
  const { t } = useTranslation();
  const dropdownActive = useStore((state) => state.dropdownActive);
  const setDropdownActive = useStore((state) => state.setDropdownActive);
  const [previousLimit, setPreviousLimit] = useState<number>(limit20);
  const [send, setSend] = useState<boolean>(false);
  const [pagerLimit, setPagerLimit] = useState<number>(limit20);

  useEffect(() => {
    return () => {
      setDropdownActive(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (offset === 0) {
      // initial load
      setPagingParams(`?limit=${limit}&offset=${offset}`);
      resetTableSorting(); // Table component in parent component
      setPagerLimit(limit);
    } else if (offset > 0 && send) {
      // subsequent loads
      setPagingParams(`?limit=${limit}&offset=${offset}`);
      resetTableSorting(); // Table component in parent component
      setSend(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, send]);

  const DropDown = () => {
    return (
      <div className={dropdownActive ? "dropdown is-active" : "dropdown"}>
        <div className="dropdown-trigger">
          <button
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={() => setDropdownActive(!dropdownActive)}
            type="button"
          >
            <span>{limit}</span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a
              className="dropdown-item"
              onClick={() => {
                setPreviousLimit(limit);
                setLimit(limit20);
                setDropdownActive(false);
              }}
            >
              {limit20}
            </a>
            <a
              className="dropdown-item"
              onClick={() => {
                setPreviousLimit(limit);
                setLimit(limit50);
                setDropdownActive(false);
              }}
            >
              {limit50}
            </a>
            <a
              className="dropdown-item"
              onClick={() => {
                setPreviousLimit(limit);
                setLimit(limit100);
                setDropdownActive(false);
              }}
            >
              {limit100}
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="columns">
      <div className="column">
        <div className="field is-grouped is-grouped-centered">
          <div className="control">
            <label className="label">{t("Rows per page")}</label>
          </div>
          <div className="control">
            <DropDown />
          </div>

          <div className="control">
            <button className="button is-white" type="button" disabled={true}>
              {offset + 1} -{" "}
              {offset + pagerLimit >= total ? total : offset + pagerLimit}
              {"/"}
              {total}
            </button>
          </div>

          <div className="control">
            <button
              className="button is-primary"
              type="button"
              onClick={() => {
                setOffset(Math.max(0, offset - limit));
                setPagerLimit(limit);
                setPreviousLimit(limit);
                setSend(true);
              }}
              disabled={offset === 0}
            >
              <span className="icon">
                <i className="mdi mdi-chevron-left" aria-hidden="true"></i>
              </span>
            </button>
          </div>

          <div className="control">
            <button
              className="button is-primary"
              type="button"
              onClick={() => {
                if (offset === 0) {
                  setOffset(offset + limit);
                } else {
                  setOffset(offset + previousLimit);
                }
                setPagerLimit(limit);
                setPreviousLimit(limit);
                setSend(true);
              }}
              disabled={offset + pagerLimit >= total}
            >
              <span className="icon">
                <i className="mdi mdi-chevron-right" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
