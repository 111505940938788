import { getSession } from "../aws/cognito";
import { ERROR_SESSION_IS_NOT_VALID } from "../constants";

export const sessionWrapper = async (
  callback: Function,
  ...originalArgs: any
) => {
  try {
    const session = await getSession();
    if (session.isValid()) {
      const Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
      return await callback(...originalArgs, Authorization);
    } else {
      throw new Error(ERROR_SESSION_IS_NOT_VALID);
    }
  } catch (error) {
    return {
      error: error || "Error in sessionWrapper",
    };
  }
};
