import { QUOTES_REGEX } from "../constants";
import { KeyValues } from "./types";

export const ValidateKeyValues = (key_values: KeyValues[] | null) => {
  if (!key_values) return;

  const keyValues = [...key_values];

  if (keyValues && keyValues.length > 0) {
    keyValues.forEach((value) => {
      value.value = value.value.replace(QUOTES_REGEX, "");
      value.value = value.value.trim();
    });
  }
  return keyValues;
};
