import { useEffect, useState } from "react";
import { ASC, DESC, OFF } from "../constants";

interface TableProps {
  data: any[];
  columns: any[];
  sorter?: Function;
  sortingState?: string;
}

export const Table: React.FC<TableProps> = ({
  data,
  columns,
  sorter,
  sortingState,
}) => {
  const [columnKeys, setColumnKeys] = useState<string[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<string>("");

  useEffect(() => {
    if (data && data?.length > 0 && columns && columns?.length > 0) {
      const keys = columns.flatMap((column) => Object.keys(column));
      setColumnKeys(keys);
    }
  }, [columns, data]);

  const handleSort = (column: string, sorter: Function | undefined) => {
    setSelectedColumn(Object.keys(column)[0]);
    if (sorter) {
      sorter(column, data);
    }
  };

  const setClassNameByColumn = (
    column: string,
    sortingState: string
  ): string => {
    if (Object.keys(column)[0] === "id") {
      return "is-hidden";
    }
    if (
      selectedColumn === Object.keys(column)[0] &&
      sortingState &&
      sortingState !== OFF
    ) {
      return "is-selected";
    }
    return "is-table-header";
  };

  const setSortingStateIndicatorByColumn = (
    column: string,
    sortingState: string
  ): string => {
    if (
      selectedColumn === Object.keys(column)[0] &&
      sortingState &&
      sortingState === ASC
    ) {
      return "mdi mdi-sort-ascending";
    } else if (
      selectedColumn === Object.keys(column)[0] &&
      sortingState &&
      sortingState === DESC
    ) {
      return "mdi mdi-sort-descending";
    } else {
      // OFF
      return "";
    }
  };

  return (
    <table className="table is-bordered is-striped is-hoverable">
      <thead>
        <tr>
          {columns.map((column: string, index: number) => (
            <th
              key={index}
              onClick={() => handleSort(column, sorter)}
              className={setClassNameByColumn(column, sortingState || "")}
            >
              {Object.values(column)}
              <span className="icon">
                <i
                  className={setSortingStateIndicatorByColumn(
                    column,
                    sortingState || ""
                  )}
                ></i>
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data?.length > 0 &&
          data.map((item: any, index: number) => (
            <tr key={index}>
              {columnKeys.map((column, index) => (
                <td key={index} className={column === "id" ? "is-hidden" : ""}>
                  {item[column]}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};
