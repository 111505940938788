import React, { useEffect, useState } from "react";
import { useFetch } from "../dal/fetchHook";
import { BACKEND_ORGANIZATION_WITH_USERS } from "../constants";
import { CreateButton } from "../components/createButton";
import useStore from "../store";
import { CardModal } from "../components/cardModal";
import { CreateCompanyForm } from "./createCompanyForm";
import { useNavigate } from "react-router-dom";
import { UpdateCompanyForm } from "./updateCompanyForm";
import { DeleteCompanyForm } from "./deleteCompanyForm";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../components/mainContainer";
import { CreateUserForm } from "./createUserForm";
import { JoinApplicationToCompanyForm } from "./joinApplicationToCompanyForm";
import { JoinUserToGroupForm } from "./joinUserToGroupForm";

interface CompanyData {
  id: number;
  company: string;
  company_name: string;
  site: string;
  user_pool_name: string;
  user_pool_ID: string;
  client_ID: string;
  client_secret: string | null;
  region: string;
  created: string;
  modified: string | null;
  deleted: string | null;
  organization_app_group_users: [];
  organization_users: [];
  organization_apps: [];
}

const Companies: React.FC = () => {
  const { t } = useTranslation();
  const [url] = useState(
    `${process.env.REACT_APP_BACKEND}${BACKEND_ORGANIZATION_WITH_USERS}`
  );
  const { data, isPending, error } = useFetch(url);
  const setGlobalError = useStore((state) => state.setGlobalError);
  const company = useStore((state) => state.company);
  const groupName = useStore((state) => state.groupName);
  const idAndName = useStore((state) => state.idAndName);
  const [createCompany, setCreateCompany] = useState(false);
  const [deleteCompany, setDeleteCompany] = useState(false);
  const [updateCompany, setUpdateCompany] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [joinApplication, setJoinApplication] = useState(false);
  const [joinUser, setJoinUser] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (error?.message) {
      setGlobalError(error.message);
    }
    if (data?.status >= 400) {
      const errorResponse = `${data?.name} ${data?.message} (${data?.status})`;
      setGlobalError(errorResponse);
    }
  }, [data, isPending, error, setGlobalError]);

  useEffect(() => {
    if (reload) {
      navigate(0);
      setReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      {data &&
        data?.length > 0 &&
        data.map((item: CompanyData) => (
          <MainContainer
            key={item.id}
            name={item.company_name}
            update={updateCompany}
            setUpdate={setUpdateCompany}
          >
            <UpdateCompanyForm
              data={item}
              update={updateCompany}
              setUpdate={setUpdateCompany}
              setReload={setReload}
              setDeleteCompany={setDeleteCompany}
              setCreateUser={setCreateUser}
              setJoinApplication={setJoinApplication}
              setJoinUser={setJoinUser}
            />
          </MainContainer>
        ))}
      <CreateButton callback={() => setCreateCompany(true)} />
      <CardModal
        title={t("Create new company")}
        active={createCompany}
        setActive={setCreateCompany}
      >
        <CreateCompanyForm setReload={setReload} setActive={setCreateCompany} />
      </CardModal>
      <CardModal
        title={t("Delete company")}
        active={deleteCompany}
        setActive={setDeleteCompany}
      >
        <DeleteCompanyForm
          data={idAndName}
          setActive={setDeleteCompany}
          setReload={setReload}
        />
      </CardModal>
      <CardModal
        title={t("Add new user to company", { company })}
        active={createUser}
        setActive={setCreateUser}
      >
        <CreateUserForm setReload={setReload} setActive={setCreateUser} />
      </CardModal>
      <CardModal
        title={t("Join user to group", { groupName })}
        active={joinUser}
        setActive={setJoinUser}
      >
        <JoinUserToGroupForm
          setReload={setReload}
          joinUser={joinUser}
          setActive={setJoinUser}
        />
      </CardModal>
      <CardModal
        title={t("Join OR remove application with company", { company })}
        active={joinApplication}
        setActive={setJoinApplication}
      >
        <JoinApplicationToCompanyForm
          setReload={setReload}
          joinApplication={joinApplication}
          setActive={setJoinApplication}
        />
      </CardModal>
    </>
  );
};

export default Companies;
