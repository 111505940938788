interface CustomError {
  message: string;
}

export const promiseAll = async <T>(
  promises: Promise<T>[]
): Promise<T[] | CustomError> => {
  try {
    return await Promise.all(promises);
  } catch (error) {
    return { message: (error as Error).message };
  }
};
