import { FieldErrors, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  GROUP_DESCRIPTION,
  GROUPS,
  GROUP_NAME,
  VALID_GROUP_NAME_REGEX,
} from "../constants";

type UpdateListOfGroupsProps = {
  update: boolean;
  setUpdate: Function;
};

export const UpdateListOfGroupsForm = ({
  update,
  setUpdate,
}: UpdateListOfGroupsProps) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const { fields, remove } = useFieldArray({
    control,
    name: GROUPS,
  });

  const Groups = () => (
    <>
      {fields.map((field, index) => {
        // @ts-ignore
        const group_name = field[GROUP_NAME];
        // @ts-ignore
        const group_description = field[GROUP_DESCRIPTION];

        const formErrors = errors as FieldErrors<{
          [GROUPS]: {
            [key: string]: {
              [GROUP_NAME]: string;
              [GROUP_DESCRIPTION]: string;
            };
          };
        }>;

        return (
          <div className="columns" key={field.id}>
            <div className="column is-4">
              <div className="field">
                <div className="control">
                  {/* READ ONLY */}
                  <input
                    className={update ? "input is-hidden" : "input"}
                    type="text"
                    defaultValue={group_name}
                    disabled={true}
                    onClick={() => setUpdate(true)}
                  />
                  {/* READ ONLY */}
                  <input
                    className={update ? "input" : "input is-hidden"}
                    type="text"
                    placeholder={t("Add group name")}
                    disabled={true}
                    {...register(`${GROUPS}.${index}.${GROUP_NAME}`, {
                      required: true,
                      pattern: VALID_GROUP_NAME_REGEX,
                    })}
                  />
                  {formErrors?.[GROUPS]?.[index]?.[GROUP_NAME] && (
                    <p className="has-text-danger">
                      {t("This field must match with pattern", {
                        pattern: VALID_GROUP_NAME_REGEX,
                      })}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="column is-6">
              <div className="field">
                <div className="control">
                  {/* READ */}
                  <input
                    className={update ? "input is-hidden" : "input"}
                    type="text"
                    defaultValue={group_description}
                    readOnly={true}
                    onClick={() => setUpdate(true)}
                  />
                  {/* UPDATE */}
                  <input
                    className={update ? "input" : "input is-hidden"}
                    type="text"
                    placeholder={t("Add group description")}
                    {...register(`${GROUPS}.${index}.${GROUP_DESCRIPTION}`)}
                  />
                </div>
              </div>
            </div>
            <div className="column is-2">
              <div className="field">
                <div className="control">
                  <button
                    className={
                      update
                        ? "button is-small is-danger is-pulled-right"
                        : "button is-small is-danger is-pulled-right is-hidden"
                    }
                    type="button"
                    onClick={() => remove(index)}
                  >
                    {t("Delete")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );

  return (
    <>
      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <label className="label">{t("Group name")}</label>
          </div>
        </div>
        <div className="column is-6">
          <div className="field">
            <label className="label">{t("Group description")}</label>
          </div>
        </div>
        <div className="column is-2">
          <div className="field"></div>
        </div>
      </div>
      <Groups />
    </>
  );
};
