import { FieldErrors, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useStore from "../store";
import { ORGANIZATION_APPS } from "../constants";
import { useCallback, useEffect, useState } from "react";

type ApplicationsTabProps = {
  update: boolean;
  setUpdate: Function;
  setJoinApplication: Function;
  setJoinUser: Function;
  company: string;
  id: number;
  organizationAppsCopy: any[];
  resetOrganizationAppKeyValues: boolean;
  setResetOrganizationAppKeyValues: Function;
};

type OrganizationAppKeyValues = {
  app_id: any;
  resetButtonIndex: number[];
  value: string;
};

const areDeeplyEqual = (
  currentArray: any[],
  defaultArray: any[]
): OrganizationAppKeyValues[] => {
  const organizationAppKeyValues: OrganizationAppKeyValues[] = [];

  currentArray.forEach((currentApp, appIndex) => {
    const defaultApp = defaultArray[appIndex];

    if (currentApp.app_id !== defaultApp.app_id) {
      console.error("App IDs do not match");
      return;
    }

    const resetButtonIndex: number[] = [];

    if (currentApp.key_values && defaultApp.key_values) {
      currentApp.key_values.forEach((kv: { value: any }, index: number) => {
        if (!defaultApp.key_values[index]) {
          console.error("Key value structure mismatch");
          return;
        }

        if (kv.value !== defaultApp.key_values[index].value) {
          resetButtonIndex.push(index);
        }
      });
    }

    if (resetButtonIndex.length > 0) {
      organizationAppKeyValues.push({
        app_id: currentApp.app_id,
        resetButtonIndex: resetButtonIndex,
        value: "Custom key values",
      });
    }
  });

  return organizationAppKeyValues;
};

export const ApplicationsTab = ({
  update,
  setUpdate,
  setJoinApplication,
  setJoinUser,
  company,
  id,
  organizationAppsCopy,
  resetOrganizationAppKeyValues,
  setResetOrganizationAppKeyValues,
}: ApplicationsTabProps) => {
  const { t } = useTranslation();
  const setCompany = useStore((state) => state.setCompany);
  const setId = useStore((state) => state.setId);
  const setGroupName = useStore((state) => state.setGroupName);
  const setGroupId = useStore((state) => state.setGroupId);
  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: ORGANIZATION_APPS,
  });
  const organizationApps = watch(ORGANIZATION_APPS);
  const [organizationAppKeyValues, setOrganizationAppKeyValues] = useState<
    OrganizationAppKeyValues[]
  >([]);

  useEffect(() => {
    if (resetOrganizationAppKeyValues || !update) {
      setOrganizationAppKeyValues([]);

      if (resetOrganizationAppKeyValues) {
        setResetOrganizationAppKeyValues(false);
      }

      if (!update) {
        reset();
      }
    }
  }, [
    reset,
    update,
    resetOrganizationAppKeyValues,
    setResetOrganizationAppKeyValues,
  ]);

  const handleChanges = useCallback(() => {
    const currentOrganizationApps = organizationApps.map((app: any) => ({
      app_id: app.app_id,
      key_values: app.key_values,
    }));

    const defaultOrganizationApps = organizationAppsCopy.map((app: any) => ({
      app_id: app.app_id,
      key_values: app.key_values,
    }));

    setOrganizationAppKeyValues(
      areDeeplyEqual(currentOrganizationApps, defaultOrganizationApps)
    );
  }, [organizationApps, organizationAppsCopy]);

  useEffect(() => {
    handleChanges();
  }, [handleChanges]);

  const handleKeyValueLabel = (app_id: string): string => {
    let response = "Default key values";

    for (const organizationAppKeyValue of organizationAppKeyValues) {
      if (organizationAppKeyValue.app_id === app_id) {
        response = organizationAppKeyValue.value;
      }
    }

    return t(response);
  };

  const handleResetButtonDisabled = (
    app_id: string,
    index: number,
    update: boolean
  ): boolean => {
    if (!update) return true;
    let response = true;

    for (const organizationAppKeyValue of organizationAppKeyValues) {
      if (
        organizationAppKeyValue.app_id === app_id &&
        organizationAppKeyValue.resetButtonIndex.includes(index)
      ) {
        response = false;
      }
    }

    return response;
  };

  return (
    <>
      {fields.map((field, outerIndex) => {
        // @ts-ignore
        const name = field["name"];
        // @ts-ignore
        const app_id = field["app_id"];
        // @ts-ignore
        const groups = field["groups"];
        // @ts-ignore
        const key_values = field["key_values"];

        const formErrors = errors as FieldErrors<{
          [ORGANIZATION_APPS]: {
            [key: string]: {
              // name: string;
              // app_id: string;
              // groups: any[];
              key_values: { key: string; value: string }[];
            };
          };
        }>;

        return (
          <div key={field.id}>
            <div className="columns">
              <div className="column is-6">
                <div className="field">
                  <label className="label">{t("Application name")}</label>
                  {/* READ ONLY */}
                  <input
                    className="input"
                    type="text"
                    defaultValue={name}
                    disabled={true}
                    onClick={() => setUpdate(true)}
                  />
                </div>
              </div>
              <div className="column is-6">
                <div className="field">
                  <label className="label">{t("Application ID")}</label>
                  {/* READ ONLY */}
                  <input
                    className="input"
                    type="text"
                    defaultValue={app_id}
                    disabled={true}
                    onClick={() => setUpdate(true)}
                  />
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <label
                    className={
                      key_values && key_values.length > 0
                        ? "label"
                        : "label is-hidden"
                    }
                  >
                    {handleKeyValueLabel(app_id)}
                  </label>
                  <div className="control">
                    {key_values &&
                      key_values.length > 0 &&
                      key_values.map((keyValue: any, index: number) => (
                        <div key={index}>
                          <div className="columns">
                            <div className="column is-4">
                              {/* READ ONLY */}
                              <input
                                className="input"
                                type="text"
                                defaultValue={keyValue.key}
                                disabled={true}
                                onClick={() => setUpdate(true)}
                              />
                            </div>
                            <div className="column is-6">
                              {/* READ */}
                              <input
                                className={update ? "input is-hidden" : "input"}
                                type="text"
                                defaultValue={keyValue.value}
                                readOnly={true}
                                onClick={() => setUpdate(true)}
                              />
                              {/* UPDATE */}
                              <input
                                className={update ? "input" : "input is-hidden"}
                                type="text"
                                placeholder={t("Add key")}
                                {...register(
                                  `${ORGANIZATION_APPS}.${outerIndex}.key_values.${index}.value`,
                                  { required: true }
                                )}
                              />
                              {formErrors?.[ORGANIZATION_APPS]?.[outerIndex]
                                ?.key_values?.[index]?.value && (
                                <p className="has-text-danger">
                                  {t("This field is required")}
                                </p>
                              )}
                            </div>
                            <div className="column is-2">
                              <button
                                className="button is-primary is-small mr-3"
                                type="button"
                                disabled={handleResetButtonDisabled(
                                  app_id,
                                  index,
                                  update
                                )}
                                onClick={() => {
                                  setValue(
                                    `${ORGANIZATION_APPS}[${outerIndex}].key_values[${index}].value`,
                                    organizationAppsCopy[outerIndex].key_values[
                                      index
                                    ].value,
                                    {
                                      shouldValidate: true,
                                    }
                                  );

                                  setOrganizationAppKeyValues([]);
                                }}
                              >
                                {t("reset default value")}
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <label className="label">{t("Groups")}</label>
                  <div className="control">
                    {groups &&
                      groups.length > 0 &&
                      groups.map((group: any, index: number) => (
                        <button
                          key={index}
                          className="button is-primary is-small mr-3"
                          type="button"
                          onClick={() => {
                            setJoinUser(true);
                            setGroupName(group.name);
                            setGroupId(group.id);
                            setId(id);
                          }}
                        >
                          {group.name}
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        );
      })}
      <div className="columns">
        <div className="column is-12">
          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button
                className="button is-primary"
                type="button"
                disabled={false}
                onClick={() => {
                  setJoinApplication(true);
                  setCompany(company);
                  setId(id);
                }}
              >
                {t("Join OR remove application")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
