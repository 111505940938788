/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import {
  Application,
  OrganizationUser,
} from "../companies/joinUserToGroupForm";
import useStore from "../store";
import { Company } from "../productionLines/createProductionLineForm";

type DropdownProps = {
  dropdownTriggerText: string;
  data: any[];
  append?: Function;
  reset?: boolean;
  setReset?: Function;
  setValue?: Function;
  fieldName?: string;
};

type DropDownItemForUsersProps = {
  item: OrganizationUser;
  index: number;
};

type DropDownItemForAppsProps = {
  item: Application;
  index: number;
};

type DropDownItemForCompaniesProps = {
  item: Company;
  index: number;
};

export const Dropdown = ({
  dropdownTriggerText,
  data,
  append,
  reset,
  setReset,
  setValue,
  fieldName,
}: DropdownProps) => {
  const dropdownActive = useStore((state) => state.dropdownActive);
  const setDropdownActive = useStore((state) => state.setDropdownActive);
  const [localData, setLocalData] = useState<any[]>();

  const sortData = (data: any[]) => {
    let sortedData: any[] = [];
    if (data && data.length > 0) {
      if (data[0].email) {
        // user
        sortedData = [...data].sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      } else if (data[0].app_id) {
        // application
        sortedData = [...data].sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      } else {
        // company
        sortedData = [...data].sort((a, b) => {
          return a.company.localeCompare(b.company);
        });
      }
    }
    return sortedData;
  };

  useEffect(() => {
    if (data) {
      setLocalData(sortData(data));
    }
  }, [data]);

  useEffect(() => {
    if (reset) {
      setLocalData(sortData(data));
      setReset && setReset(!reset);
    }
  }, [data, reset, setReset]);

  const DropDownItemForUsers = ({ item, index }: DropDownItemForUsersProps) => {
    return (
      <a
        onClick={(event) => {
          event.preventDefault();
          append && append(item);
          setDropdownActive(false);
          localData?.splice(index, 1); // remove the item from the dropdown
        }}
        className="dropdown-item"
      >
        {item.name} - {item.email} - {item.cognito_username} - {item.sap_id}
      </a>
    );
  };
  const DropDownItemForApps = ({ item, index }: DropDownItemForAppsProps) => {
    return (
      <a
        onClick={(event) => {
          event.preventDefault();
          append && append(item);
          setDropdownActive(false);
          localData?.splice(index, 1); // remove the item from the dropdown
        }}
        className="dropdown-item"
      >
        {item.name} - {item.app_id}
      </a>
    );
  };
  const DropDownItemForCompanies = ({
    item,
  }: DropDownItemForCompaniesProps) => {
    return (
      <a
        onClick={(event) => {
          event.preventDefault();
          setValue && fieldName && setValue(fieldName, item.id);
          setDropdownActive(false);
        }}
        className="dropdown-item"
      >
        {item.company} - {item.company_name}
      </a>
    );
  };

  // Type Guard: OrganizationUser CONTAINS email
  const isOrganizationUser = (item: any): item is OrganizationUser => {
    return (item as OrganizationUser).email !== undefined;
  };

  // Type Guard: Application CONTAINS app_id
  const IsApplication = (item: any): item is Application => {
    return (item as Application).app_id !== undefined;
  };

  return (
    <div className={dropdownActive ? "dropdown is-active" : "dropdown"}>
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => setDropdownActive(!dropdownActive)}
          type="button"
          disabled={localData && localData.length === 0}
        >
          <span>{dropdownTriggerText}</span>
          <span className="icon is-small">
            <i className="mdi mdi-chevron-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {localData &&
            localData.length > 0 &&
            localData.map((item, index) => {
              if (isOrganizationUser(item)) {
                return (
                  <DropDownItemForUsers key={index} item={item} index={index} />
                );
              } else if (IsApplication(item)) {
                return (
                  <DropDownItemForApps key={index} item={item} index={index} />
                );
              } else {
                return (
                  <DropDownItemForCompanies
                    key={index}
                    item={item}
                    index={index}
                  />
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};
