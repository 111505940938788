import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BACKEND_APPS, DELETE } from "../constants";
import useStore from "../store";
import { postData } from "../dal/fetchRequest";
import { sessionWrapper } from "../dal/sessionWarpper";

type DeleteApplicationFormProps = {
  data: DeleteApplicationInputs;
  setActive: Function;
  setReload: Function;
};

type DeleteApplicationInputs = {
  id: number | null;
  name: string | null;
};

type DeleteData = {
  id: number | null;
};

export const DeleteApplicationForm = ({
  data,
  setActive,
  setReload,
}: DeleteApplicationFormProps) => {
  const { t } = useTranslation();
  const { id, name } = data;
  const [disabled, setDisabled] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_APPS}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const resetIdAndName = useStore((state) => state.resetIdAndName);
  const { handleSubmit, reset } = useForm<DeleteApplicationInputs>();

  const onSubmit: SubmitHandler<DeleteApplicationInputs> = async (data) => {
    console.log(data);
    setDisabled(true);

    const applicationData: DeleteData = {
      id,
    };

    console.log({ applicationData });

    const response: any = await sessionWrapper(
      postData,
      DELETE,
      url,
      applicationData
    );
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    setActive(false);
    reset();
    setDisabled(false);
    resetIdAndName();
  };

  return (
    <form id="update-company-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        <label className="label">{t("Application")}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            value={name ? name : ""}
            disabled={true}
          />
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button"
            type="reset"
            onClick={() => {
              setActive(false);
              setDisabled(false);
              resetIdAndName();
            }}
          >
            {t("Cancel")}
          </button>
        </div>
        <div className="control">
          <button
            className="button is-primary"
            type="submit"
            disabled={disabled}
          >
            {t("Delete")}
          </button>
        </div>
      </div>
    </form>
  );
};
