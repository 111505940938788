import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BACKEND_ORGANIZATION_WITH_USERS,
  ORGANIZATION_APPS,
  ORGANIZATION_APP_GROUP_USERS,
  ORGANIZATION_USERS,
  PUT,
} from "../constants";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { postData } from "../dal/fetchRequest";
import useStore from "../store";
import { sessionWrapper } from "../dal/sessionWarpper";
import { Tabs } from "../components/tabs";
import { ApplicationsTab } from "./applicationsTab";
import { UsersTab } from "./usersTab";
import { ValidateKeyValues } from "../applications/helpers";

type UpdateCompanyFormProps = {
  data: UpdateCompanyInputs;
  update: boolean;
  setUpdate: Function;
  setReload: Function;
  setDeleteCompany: Function;
  setCreateUser: Function;
  setJoinApplication: Function;
  setJoinUser: Function;
};

type UpdateCompanyInputs = {
  id: number;
  company: string;
  company_name: string;
  site: string;
  user_pool_ID: string;
  organization_app_group_users: any[];
  organization_users: any[];
  organization_apps: any[];
};

type UpdateData = {
  id: number;
  company?: string;
  company_name: string;
  site: string;
  user_pool_name?: string;
  organization_app_group_users: any[];
  organization_users: any[];
  organization_apps: any[];
};

const organizationAppsKeyValuesHandler = (organizationApps: any[]): any[] => {
  const deepClonedOrganizationApps = JSON.parse(
    JSON.stringify(organizationApps)
  );

  for (const organizationApp of deepClonedOrganizationApps) {
    if (
      organizationApp.key_values &&
      organizationApp.key_values.length > 0 &&
      organizationApp.custom_key_values &&
      organizationApp.custom_key_values.length > 0
    ) {
      const mergedObject: any = {};

      // Add all key_values to the merged object
      organizationApp.key_values.forEach(
        (kv: { key: string | number; value: any }) => {
          mergedObject[kv.key] = kv.value;
        }
      );

      // Update or add custom_key_values to the merged object
      organizationApp.custom_key_values.forEach(
        (ckv: { key: string | number; value: any }) => {
          mergedObject[ckv.key] = ckv.value;
        }
      );

      // Convert the merged object back to an array
      const mergedArray = Object.keys(mergedObject).map((key) => ({
        key: key,
        value: mergedObject[key],
      }));

      // Updating the original key_values array
      organizationApp.key_values = mergedArray;
    }
  }

  return deepClonedOrganizationApps;
};

export const UpdateCompanyForm = ({
  data,
  update,
  setUpdate,
  setReload,
  setDeleteCompany,
  setCreateUser,
  setJoinApplication,
  setJoinUser,
}: UpdateCompanyFormProps) => {
  const { t } = useTranslation();
  const {
    id,
    company,
    company_name,
    site,
    user_pool_ID,
    organization_app_group_users,
    organization_users,
    organization_apps, // ORIG organization_apps
  } = data;
  const organizationAppsCopy = JSON.parse(JSON.stringify(organization_apps)); // deep clone of ORIG organization_apps
  const [disabled, setDisabled] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_ORGANIZATION_WITH_USERS}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const setIdAndName = useStore((state) => state.setIdAndName);
  const [resetOrganizationAppKeyValues, setResetOrganizationAppKeyValues] =
    useState(false);

  const myDefaultValues = {
    company,
    company_name,
    site,
    organization_app_group_users,
    organization_users,
    organization_apps: organizationAppsKeyValuesHandler(organization_apps),
  };

  const methods = useForm<UpdateCompanyInputs>({
    defaultValues: myDefaultValues,
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (update) {
      setValue("company", company);
      setValue("company_name", company_name);
      setValue("site", site);
      setValue(ORGANIZATION_APP_GROUP_USERS, organization_app_group_users);
      setValue(ORGANIZATION_USERS, organization_users);
      setValue(
        ORGANIZATION_APPS,
        organizationAppsKeyValuesHandler(organization_apps)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  useEffect(() => {
    setValue(ORGANIZATION_APP_GROUP_USERS, organization_app_group_users);
    setValue(ORGANIZATION_USERS, organization_users);
    setValue(
      ORGANIZATION_APPS,
      organizationAppsKeyValuesHandler(organization_apps)
    );
  }, [
    organization_app_group_users,
    organization_users,
    organization_apps,
    setValue,
  ]);

  const onSubmit: SubmitHandler<UpdateCompanyInputs> = async (data) => {
    console.log(data);
    setDisabled(true);

    const organizationApps = data.organization_apps.map((app) => {
      if (!app.key_values) app.key_values = [];

      // Validated key_values are passed to custom_key_values...
      app.custom_key_values = ValidateKeyValues(app.key_values);

      // ...key_values are reset to original key_values...
      app.key_values = organizationAppsCopy.find(
        (appCopy: { id: any }) => appCopy.id === app.id
      )?.key_values;

      // ...and ONLY changed custom_key_values are passed to changedKeyValues
      const changedKeyValues = app.custom_key_values.filter(
        (customKV: { key: any; value: any }) =>
          !app.key_values.some(
            (kv: { key: any; value: any }) =>
              kv.key === customKV.key && kv.value === customKV.value
          )
      );

      app.custom_key_values = [...changedKeyValues];

      return app;
    });

    const companyData: UpdateData = {
      id,
      company_name: data.company_name,
      site: data.site,
      organization_app_group_users: data.organization_app_group_users,
      organization_users: data.organization_users,
      organization_apps: organizationApps,
    };

    console.log({ companyData });

    const response: any = await sessionWrapper(postData, PUT, url, companyData);
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    reset();
    setDisabled(false);
  };

  return (
    <FormProvider {...methods}>
      <form id="update-company-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label className="label">{t("Company")}</label>
          <div className="control">
            {/* READ */}
            <input
              className="input"
              type="text"
              value={company}
              disabled={true}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Company name")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={company_name}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Company name from Valmet Compass")}
              {...register("company_name", { required: true })}
            />
            {errors.company_name && (
              <p className="has-text-danger">{t("This field is required")}</p>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Site")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={site}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Site from Valmet Compass")}
              {...register("site", { required: true })}
            />
            {errors.site && (
              <p className="has-text-danger">{t("This field is required")}</p>
            )}
          </div>
        </div>

        <Tabs
          tabs={[t("Applications"), t("Users")]}
          content={[
            <ApplicationsTab
              update={update}
              setUpdate={setUpdate}
              setJoinApplication={setJoinApplication}
              setJoinUser={setJoinUser}
              company={company}
              id={id}
              organizationAppsCopy={organizationAppsCopy}
              resetOrganizationAppKeyValues={resetOrganizationAppKeyValues}
              setResetOrganizationAppKeyValues={
                setResetOrganizationAppKeyValues
              }
            />,
            <UsersTab
              update={update}
              setUpdate={setUpdate}
              setCreateUser={setCreateUser}
              user_pool_ID={user_pool_ID}
              company={company}
            />,
          ]}
        />

        <hr />

        <div
          className={
            update
              ? "field is-grouped is-grouped-right"
              : "field is-grouped is-grouped-right is-hidden"
          }
        >
          <div className="control">
            <button
              className="button"
              type="reset"
              onClick={() => {
                reset();
                setDisabled(false);
                setUpdate(false);
                setResetOrganizationAppKeyValues(true);
              }}
            >
              {t("Cancel")}
            </button>
          </div>
          <div className="control">
            <button
              className="button is-primary"
              type="submit"
              disabled={disabled}
            >
              {t("Save changes")}
            </button>
          </div>
        </div>

        <div
          className={
            update
              ? "field is-grouped is-grouped-right is-hidden"
              : "field is-grouped is-grouped-right"
          }
        >
          <div className="control">
            <button
              className="button is-primary"
              type="button"
              disabled={disabled}
              onClick={() => {
                setDeleteCompany(true);
                setIdAndName(id, company);
              }}
            >
              {t("Delete company")}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
