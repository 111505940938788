import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BACKEND_PRODUCTION_LINES } from "../constants";
import { useFetch } from "../dal/fetchHook";
import useStore from "../store";
import { useNavigate } from "react-router-dom";
import { MainContainer } from "../components/mainContainer";
import { UpdateProductionLinesForm } from "./updateProductionLines";
import { CreateButton } from "../components/createButton";
import { CardModal } from "../components/cardModal";
import { CreateProductionLineForm } from "./createProductionLineForm";
import { DeleteProductionLineForm } from "./deleteProductionLineForm";

export interface ProductionLineData {
  id: number;
  company: string;
  production_line_user_group_ID: string;
  production_line_description: string;
  ValmetID: string | null;
  MillID: string | null;
  Production_line_ID: string | null;
  FlowControlID: string | null;
  AFRY_Mill_No: string | null;
  Default_Mechanical_FL: string | null;
  Default_Automation_FL: string | null;
  Default_Electrical_FL: string | null;
  Default_Instrumenation_FL: string | null;
  created: Date | string;
  modified: Date | null;
  deleted: Date | null;
  organization_id: number;
}

const ProductionLines: React.FC = () => {
  const { t } = useTranslation();
  const [url] = useState(
    `${process.env.REACT_APP_BACKEND}${BACKEND_PRODUCTION_LINES}`
  );
  const { data, isPending, error } = useFetch(url);
  const idAndName = useStore((state) => state.idAndName);
  const setGlobalError = useStore((state) => state.setGlobalError);
  const [updateProductionLines, setUpdateProductionLines] = useState(false);
  const [createProductionLine, setCreateProductionLine] = useState(false);
  const [deleteProductionLine, setDeleteProductionLine] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (error?.message) {
      setGlobalError(error.message);
    }
    if (data?.status >= 400) {
      const errorResponse = `${data?.name} ${data?.message} (${data?.status})`;
      setGlobalError(errorResponse);
    }
  }, [data, isPending, error, setGlobalError]);

  useEffect(() => {
    if (reload) {
      navigate(0);
      setReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      {data &&
        data?.length > 0 &&
        data.map((item: ProductionLineData) => (
          <MainContainer
            key={item.id}
            name={`${item.company} / ${item.production_line_user_group_ID}`}
            update={updateProductionLines}
            setUpdate={setUpdateProductionLines}
          >
            <UpdateProductionLinesForm
              data={item}
              update={updateProductionLines}
              setUpdate={setUpdateProductionLines}
              setReload={setReload}
              setDelete={setDeleteProductionLine}
            />
          </MainContainer>
        ))}
      <CreateButton callback={() => setCreateProductionLine(true)} />
      <CardModal
        title={t("Create new production line")}
        active={createProductionLine}
        setActive={setCreateProductionLine}
      >
        <CreateProductionLineForm
          setReload={setReload}
          createProductionLine={createProductionLine}
          setActive={setCreateProductionLine}
        />
      </CardModal>
      <CardModal
        title={t("Delete production line")}
        active={deleteProductionLine}
        setActive={setDeleteProductionLine}
      >
        <DeleteProductionLineForm
          data={idAndName}
          setActive={setDeleteProductionLine}
          setReload={setReload}
        />
      </CardModal>
    </>
  );
};

export default ProductionLines;
