import useStore from "../store";

interface CardModalProps {
  title: string;
  active: boolean;
  setActive: Function;
  children: React.ReactNode;
}

export const CardModal = ({
  title,
  active,
  setActive,
  children,
}: CardModalProps) => {
  const setDropdownActive = useStore((state) => state.setDropdownActive);

  return (
    <div className={active ? "modal is-active" : "modal"}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              setActive(false);
              setDropdownActive(false); // Close all Dropdown instances...
            }}
          ></button>
        </header>
        <section className="modal-card-body">{children}</section>
      </div>
    </div>
  );
};
