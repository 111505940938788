import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BACKEND_APPS,
  BACKEND_APP_GROUPS,
  BACKEND_GROUPS,
  ERROR_FETCHING_DATA,
  ERROR_SESSION_IS_NOT_VALID,
} from "../constants";
import { MainContainer } from "../components/mainContainer";
import { UpdateApplicationForm } from "./updateApplicationForm";
import { CreateButton } from "../components/createButton";
import { CardModal } from "../components/cardModal";
import useStore from "../store";
import { useNavigate } from "react-router-dom";
import { CreateApplicationForm } from "./createApplicationForm";
import { DeleteApplicationForm } from "./deleteApplicationForm";
import { AppGroups, Group, KeyValues } from "./types";
import { AddGroupToApplicationForm } from "./addGroupToApplicationForm";
import { getData } from "../dal/fetchRequest";
import { promiseAll } from "../dal/promiseAll";
import { getSession } from "../aws/cognito";
import { responseErrors } from "../dal/responseErrors";
import { CustomOverrides } from "./customOverrides";

interface ApplicationData {
  id: number;
  name: string;
  app_id: string;
  key_values: KeyValues[];
  groups: Group[];
  created: string;
  modified: string | null;
  deleted: string | null;
}

const addGroupToApps = (
  apps: ApplicationData[],
  groups: Group[],
  appGroups: AppGroups[]
): ApplicationData[] => {
  apps.forEach((app) => {
    // Initialize an empty array for groups in each app
    app.groups = [];

    // Find matching appGroups for the current app
    appGroups.forEach((appGroup) => {
      if (appGroup.app_id === app.id) {
        // Find the corresponding group
        const matchingGroup = groups.find(
          (group) => group.id === appGroup.group_id
        );
        if (matchingGroup) {
          // Add the group to the app's groups array
          app.groups.push(matchingGroup);
        }
      }
    });
  });

  return [...apps];
};

const Applications: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<ApplicationData[] | null>(null);
  const idAndName = useStore((state) => state.idAndName);
  const [createApplication, setCreateApplication] = useState(false);
  const [deleteApplication, setDeleteApplication] = useState(false);
  const [updateApplication, setUpdateApplication] = useState(false);
  const [addGroupToApplication, setAddGroupToApplication] = useState(false);
  const [customOverrides, setCustomOverrides] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);

  useEffect(() => {
    const fetchPromiseAll = async () => {
      const session = await getSession();
      if (session.isValid()) {
        const Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;

        const responses = await promiseAll([
          getData(
            `${process.env.REACT_APP_BACKEND}${BACKEND_APPS}`,
            Authorization
          ),
          getData(
            `${process.env.REACT_APP_BACKEND}${BACKEND_GROUPS}`,
            Authorization
          ),
          getData(
            `${process.env.REACT_APP_BACKEND}${BACKEND_APP_GROUPS}`,
            Authorization
          ),
        ]);

        if (Array.isArray(responses)) {
          if (responseErrors(responses)) {
            setGlobalError(responseErrors(responses) as string);
            return;
          } else {
            resetGlobalError();
          }

          const apps: ApplicationData[] = [...responses[0]];
          const groups: Group[] = [...responses[1]];
          const appGroups: AppGroups[] = [...responses[2]];

          const newApps = addGroupToApps(apps, groups, appGroups);

          setData(newApps);
        } else {
          setGlobalError(ERROR_FETCHING_DATA);
        }
      } else {
        setGlobalError(ERROR_SESSION_IS_NOT_VALID);
      }
    };

    fetchPromiseAll();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reload) {
      navigate(0);
      setReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      {data &&
        data?.length > 0 &&
        data.map((item: ApplicationData) => (
          <MainContainer
            key={item.id}
            name={item.name}
            update={updateApplication}
            setUpdate={setUpdateApplication}
          >
            <UpdateApplicationForm
              data={item}
              update={updateApplication}
              setUpdate={setUpdateApplication}
              setReload={setReload}
              setDelete={setDeleteApplication}
              setAddGroup={setAddGroupToApplication}
              setCustomOverrides={setCustomOverrides}
            />
          </MainContainer>
        ))}
      <CreateButton callback={() => setCreateApplication(true)} />
      <CardModal
        title={t("Create new application")}
        active={createApplication}
        setActive={setCreateApplication}
      >
        <CreateApplicationForm
          setReload={setReload}
          setActive={setCreateApplication}
        />
      </CardModal>
      <CardModal
        title={t("Delete application")}
        active={deleteApplication}
        setActive={setDeleteApplication}
      >
        <DeleteApplicationForm
          data={idAndName}
          setActive={setDeleteApplication}
          setReload={setReload}
        />
      </CardModal>
      <CardModal
        title={t("Add group to application")}
        active={addGroupToApplication}
        setActive={setAddGroupToApplication}
      >
        <AddGroupToApplicationForm
          setReload={setReload}
          setActive={setAddGroupToApplication}
        />
      </CardModal>
      <CardModal
        title={t("Custom overrides")}
        active={customOverrides}
        setActive={setCustomOverrides}
      >
        <CustomOverrides customOverrides={customOverrides} />
      </CardModal>
    </>
  );
};

export default Applications;
