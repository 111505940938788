import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BACKEND_PRODUCTION_LINES, DELETE } from "../constants";
import { SubmitHandler, useForm } from "react-hook-form";
import { sessionWrapper } from "../dal/sessionWarpper";
import { postData } from "../dal/fetchRequest";
import useStore from "../store";

type DeleteProductionLineFormProps = {
  data: DeleteProductionLineInputs;
  setActive: Function;
  setReload: Function;
};

type DeleteProductionLineInputs = {
  id: number | null;
  name: string | null;
};

type DeleteData = {
  id: number | null;
  force?: boolean;
};

export const DeleteProductionLineForm = ({
  data,
  setActive,
  setReload,
}: DeleteProductionLineFormProps) => {
  const { t } = useTranslation();
  const { id, name } = data;
  const [disabled, setDisabled] = useState(false);
  const [doubleCheck, setDoubleCheck] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_PRODUCTION_LINES}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const resetIdAndName = useStore((state) => state.resetIdAndName);
  const { handleSubmit, reset } = useForm<DeleteProductionLineInputs>();

  const onSubmit: SubmitHandler<DeleteProductionLineInputs> = async (data) => {
    console.log(data);
    setDisabled(true);

    const productionLineData: DeleteData = {
      id,
      force: doubleCheck,
    };

    console.log({ productionLineData });

    const response: any = await sessionWrapper(
      postData,
      DELETE,
      url,
      productionLineData
    );
    console.log({ response });
    if (response.data) {
      // Double check handler...
      if (response.data.doubleCheck) {
        setDoubleCheck(true);
        setDisabled(false);
      } else {
        resetGlobalError();
        setReload(true);
        setActive(false);
        reset();
        setDisabled(false);
        resetIdAndName();
      }
    } else {
      setGlobalError(response.error);
      setActive(false);
      reset();
      setDisabled(false);
      resetIdAndName();
    }
  };

  return (
    <form id="update-company-form" onSubmit={handleSubmit(onSubmit)}>
      {doubleCheck && (
        <div className="field">
          <label className="label has-text-danger">
            {t(
              "Production line has users. Force delete will remove production line with users."
            )}
          </label>
        </div>
      )}
      <div className="field">
        <label className="label">{t("Production line user group ID")}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            value={name ? name : ""}
            disabled={true}
          />
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button"
            type="reset"
            onClick={() => {
              setActive(false);
              setDisabled(false);
              resetIdAndName();
              setDoubleCheck(false);
            }}
          >
            {t("Cancel")}
          </button>
        </div>
        <div className="control">
          {doubleCheck && (
            <button
              className="button is-primary"
              type="submit"
              disabled={disabled}
            >
              {t("Force delete")}
            </button>
          )}
          {!doubleCheck && (
            <button
              className="button is-primary"
              type="submit"
              disabled={disabled}
            >
              {t("Delete")}
            </button>
          )}
        </div>
      </div>
    </form>
  );
};
