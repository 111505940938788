import {
  CONTENT_TYPE,
  APPLICATION_JSON,
  GET,
  ERROR_CONNECTION_REFUSED,
} from "../constants";

type RequestMethods = "GET" | "POST" | "PUT" | "DELETE";

export const getData = (url: RequestInfo | URL, Authorization?: string) => {
  let errorResponse: string | null = null;
  return fetch(url, {
    method: GET,
    headers: {
      [CONTENT_TYPE]: APPLICATION_JSON,
      Authorization: Authorization ? Authorization : "",
    },
  })
    .then((response) => {
      if (!response.ok) {
        errorResponse = `${GET} ${response?.url} ${response?.status} (${response?.statusText})`;
        throw new Error();
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        error: errorResponse,
      };
    });
};

export const postData = (
  method: RequestMethods,
  url: RequestInfo | URL,
  data: any,
  Authorization?: string
) => {
  let errorResponse: string | null = null;
  return fetch(url, {
    method,
    headers: {
      [CONTENT_TYPE]: APPLICATION_JSON,
      Authorization: Authorization ? Authorization : "",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        errorResponse = `${method} ${response?.url} ${response?.status} (${response?.statusText}))`;
        throw new Error();
      }
      return response.json();
    })
    .then((data) => {
      if (data.status >= 400) {
        return {
          error: JSON.stringify(data),
        };
      }
      return { data };
    })
    .catch((error) => {
      return {
        error: errorResponse ? errorResponse : ERROR_CONNECTION_REFUSED,
      };
    });
};
