import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { ProtectedRouteProps } from "../interface";
import { getSession } from "../aws/cognito";
import { Navigate } from "react-router-dom";
import { FRONTEND_ROOT } from "../constants";
import Loader from "../loader";

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);

  const ref: MutableRefObject<boolean | null> = useRef(null);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;

      getSession()
        .then((session) => {
          if (session.isValid()) {
            setHasAccess(true);
          } else {
            setHasAccess(false);
          }
        })
        .catch(() => {
          setHasAccess(false);
        });
    }
  }, []);

  if (hasAccess === null) {
    return <Loader />;
  }

  if (hasAccess) {
    return <>{children ? children : <pre>Missing children...</pre>}</>;
  }

  return <Navigate to={FRONTEND_ROOT} replace={true} />;
};
