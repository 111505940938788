import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BACKEND_APPS, GROUPS, KEY_VALUES, PUT } from "../constants";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { postData } from "../dal/fetchRequest";
import useStore from "../store";
import { UpdateKeyValuesForm } from "./updateKeyValuesForm";
import { Group, KeyValues } from "./types";
import { ValidateKeyValues } from "./helpers";
import { UpdateListOfGroupsForm } from "./updateListOfGroupsForm";
import { sessionWrapper } from "../dal/sessionWarpper";

type UpdateApplicationProps = {
  data: UpdateApplicationInputs;
  update: boolean;
  setUpdate: Function;
  setReload: Function;
  setDelete: Function;
  setAddGroup: Function;
  setCustomOverrides: Function;
};

export type UpdateApplicationInputs = {
  id: number;
  name: string;
  app_id: string;
  key_values: KeyValues[];
  groups: Group[];
};

type UpdateData = {
  id: number;
  name?: string;
  app_id?: string;
  key_values?: KeyValues[];
  groups?: Group[];
};

export const UpdateApplicationForm = ({
  data,
  update,
  setUpdate,
  setReload,
  setDelete,
  setAddGroup,
  setCustomOverrides,
}: UpdateApplicationProps) => {
  const { t } = useTranslation();
  const { id, name, app_id, key_values, groups } = data;
  const [disabled, setDisabled] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_APPS}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const setIdAndName = useStore((state) => state.setIdAndName);
  const setGlobalCustomOverrides = useStore(
    (state) => state.setGlobalCustomOverrides
  );
  const methods = useForm<UpdateApplicationInputs>({
    defaultValues: {
      name,
      app_id,
      key_values,
      groups,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (update) {
      setValue("name", name);
      setValue("app_id", app_id);
      setValue(KEY_VALUES, key_values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  useEffect(() => {
    setValue(KEY_VALUES, key_values);
    setValue(GROUPS, groups);
  }, [key_values, groups, setValue]);

  const onSubmit: SubmitHandler<UpdateApplicationInputs> = async (data) => {
    console.log(data);
    setDisabled(true);

    const applicationData: UpdateData = {
      id,
      name: data.name,
      app_id: data.app_id,
      key_values: ValidateKeyValues(data.key_values),
      groups: data.groups,
    };

    console.log({ applicationData });

    const response: any = await sessionWrapper(
      postData,
      PUT,
      url,
      applicationData
    );
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    reset();
    setDisabled(false);
  };

  return (
    <FormProvider {...methods}>
      <form id="update-application-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label className="label">{t("Application name")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={name}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add application name")}
              {...register("name", { required: true })}
            />
            {errors.name && (
              <p className="has-text-danger">{t("This field is required")}</p>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Application ID")}</label>
          <div className="control">
            {/* READ */}
            <input
              className={update ? "input is-hidden" : "input"}
              type="text"
              value={app_id}
              readOnly={true}
              onClick={() => setUpdate(true)}
            />
            {/* UPDATE */}
            <input
              className={update ? "input" : "input is-hidden"}
              type="text"
              placeholder={t("Add application ID")}
              {...register("app_id", { required: true })}
            />
            {errors.app_id && (
              <p className="has-text-danger">{t("This field is required")}</p>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Default key values")}</label>
          <div className="control">
            <UpdateKeyValuesForm update={update} setUpdate={setUpdate} />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <button
              className="button is-small is-primary"
              type="button"
              onClick={() => {
                setGlobalCustomOverrides({
                  id,
                  name,
                  app_id,
                  key_values,
                });
                setCustomOverrides(true);
              }}
            >
              {t("Show custom overrides")}
            </button>
          </div>
        </div>

        <div className="field">
          <div className="control">
            <UpdateListOfGroupsForm update={update} setUpdate={setUpdate} />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <div className="columns">
              <div className="column is-12">
                <button
                  className="button is-small is-primary"
                  type="button"
                  onClick={() => {
                    setAddGroup(true);
                    setIdAndName(id, app_id);
                  }}
                >
                  {t("Add group to application")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            update
              ? "field is-grouped is-grouped-right"
              : "field is-grouped is-grouped-right is-hidden"
          }
        >
          <div className="control">
            <button
              className="button"
              type="reset"
              onClick={() => {
                reset();
                setDisabled(false);
                setUpdate(false);
              }}
            >
              {t("Cancel")}
            </button>
          </div>
          <div className="control">
            <button
              className="button is-primary"
              type="submit"
              disabled={disabled}
            >
              {t("Save changes")}
            </button>
          </div>
        </div>

        <div
          className={
            update
              ? "field is-grouped is-grouped-right is-hidden"
              : "field is-grouped is-grouped-right"
          }
        >
          <div className="control">
            <button
              className="button is-primary"
              type="button"
              disabled={disabled}
              onClick={() => {
                setDelete(true);
                setIdAndName(id, app_id);
              }}
            >
              {t("Delete")}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
