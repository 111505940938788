import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fi from "./fi.json";
import en from "./en.json";
import { EN } from "./constants";

const resources = {
  FI: {
    translation: fi,
  },
  EN: {
    translation: en,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: EN,
    detection: {
      order: [
        "querystring",
        "localStorage",
        "cookie",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
    },

    keySeparator: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

export const changeLanguage = (lng: string) => {
  i18n.changeLanguage(lng);
};
