import { authenticate } from "../aws/cognito";
import { FRONTEND_COMPANIES, FRONTEND_ROOT, XXXXXXXX } from "../constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { ClientInfo, ErrorNotification } from "../wrapper";
import useStore from "../store";
import { useState } from "react";

type Inputs = {
  name: string;
  password: string;
};

type LoginFormProps = {
  handleSubmit: any;
  register: any;
  errors: any;
  reset: any;
  t: any;
};

const LoginForm = ({
  handleSubmit,
  register,
  errors,
  reset,
  t,
}: LoginFormProps) => {
  const navigate = useNavigate();
  const setGlobalError = useStore((state) => state.setGlobalError);
  const [disabled, setDisabled] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log({ name: data.name, password: XXXXXXXX });
    setDisabled(true);
    authenticate(data.name, data.password)
      .then((data) => {
        console.log("Logged in successfully", data);
        reset();
        navigate(FRONTEND_COMPANIES);
      })
      .catch((err) => {
        console.log("Failed to login", err.message);
        reset();
        navigate(FRONTEND_ROOT);
        setGlobalError(err.message);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return (
    <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            placeholder={t("name")}
            {...register("name", { required: true })}
          />
          {errors.name && (
            <p className="has-text-danger">{t("This field is required")}</p>
          )}
          <span className="icon is-small is-left">
            <i className="mdi mdi-account"></i>
          </span>
        </div>
      </div>

      <div className="field">
        <div className="control has-icons-left">
          <input
            className="input"
            type="password"
            placeholder={t("password")}
            {...register("password", { required: true })}
          />
          {errors.password && (
            <p className="has-text-danger">{t("This field is required")}</p>
          )}
          <span className="icon is-small is-left">
            <i className="mdi mdi-lock"></i>
          </span>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button"
            type="reset"
            onClick={() => {
              reset();
              setDisabled(false);
            }}
          >
            {t("Cancel")}
          </button>
        </div>
        <div className="control">
          <button
            className="button is-primary"
            type="submit"
            disabled={disabled}
          >
            {t("Login")}
          </button>
        </div>
      </div>
    </form>
  );
};

const Login: React.FC = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  return (
    <div className="columns is-centered">
      <div className="column is-full-mobile is-one-third">
        <section className="hero">
          <div className="hero-body">
            <h3 className="title has-text-centered">{t("Log in")}</h3>
            <ErrorNotification />
            <LoginForm
              handleSubmit={handleSubmit}
              register={register}
              errors={errors}
              reset={reset}
              t={t}
            />
            <ClientInfo />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Login;
