import {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
} from "react";
import { FetchOptions } from "../interface";
import { getSession } from "../aws/cognito";
import { APPLICATION_JSON, CONTENT_TYPE, GET } from "../constants";

const options: FetchOptions = {
  method: GET,
  headers: {
    [CONTENT_TYPE]: APPLICATION_JSON,
  },
};

export const useFetch = (
  url: string
): {
  data: any;
  isPending: boolean;
  error: Error | null;
} => {
  const [data, setData] = useState<any>(null);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async (): Promise<any> => {
    setIsPending(true);
    let response = null;
    try {
      response = await fetch(url, options);
      if (!response.ok) throw new Error(response.statusText);
      const json = await response.json();
      setIsPending(false);
      setData(json);
      setError(null);
    } catch (error) {
      const errorResponse = `${options?.method} ${response?.url} ${response?.status} (${response?.statusText})`;
      setError(Error(errorResponse));
      setIsPending(false);
    }
  }, [url]);

  const ref: MutableRefObject<boolean | null> = useRef(null);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;

      getSession()
        .then((session) => {
          if (session.isValid()) {
            options.headers = options.headers ?? {};
            options.headers.Authorization = `Bearer ${session
              .getIdToken()
              .getJwtToken()}`;

            fetchData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [fetchData]);

  return { data, isPending, error };
};
