import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { sessionWrapper } from "../dal/sessionWarpper";
import { getData, postData } from "../dal/fetchRequest";
import {
  BACKEND_ORGANIZATIONS,
  FRONTEND_PRODUCTION_LINES,
  POST,
  VALID_GROUP_NAME_REGEX,
} from "../constants";
import useStore from "../store";
import { Dropdown } from "../components/dropDown";

type CreateProductionLineFormProps = {
  setReload: Function;
  createProductionLine: boolean;
  setActive: Function;
};

type CreateProductionLineInputs = {
  company: string;
  organization_id: number | null;
  production_line_user_group_ID: string;
  production_line_description: string;
  ValmetID: string | null;
  MillID: string | null;
  Production_line_ID: string | null;
  FlowControlID: string | null;
  AFRY_Mill_No: string | null;
  Default_Mechanical_FL: string | null;
  Default_Automation_FL: string | null;
  Default_Electrical_FL: string | null;
  Default_Instrumenation_FL: string | null;
};

type CreateData = {
  company: string;
  organization_id: number | null;
  production_line_user_group_ID: string;
  production_line_description: string;
  ValmetID?: string | null;
  MillID?: string | null;
  Production_line_ID?: string | null;
  FlowControlID?: string | null;
  AFRY_Mill_No?: string | null;
  Default_Mechanical_FL?: string | null;
  Default_Automation_FL?: string | null;
  Default_Electrical_FL?: string | null;
  Default_Instrumenation_FL?: string | null;
};

export type Company = {
  id: number;
  company: string;
  company_name: string;
  site: string;
  user_pool_name: string;
  user_pool_ID: string;
  client_ID: string;
};

export const CreateProductionLineForm = ({
  setReload,
  createProductionLine,
  setActive,
}: CreateProductionLineFormProps) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const setDropdownActive = useStore((state) => state.setDropdownActive);
  const getUrl = `${process.env.REACT_APP_BACKEND}${BACKEND_ORGANIZATIONS}`;
  const postUrl = `${process.env.REACT_APP_BACKEND}${FRONTEND_PRODUCTION_LINES}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const [resetDropdown, setResetDropdown] = useState(false);
  const [fetchedData, setFetchedData] = useState<Company[]>([]);
  const methods = useForm<CreateProductionLineInputs>({
    defaultValues: {
      company: "",
      organization_id: null,
      production_line_user_group_ID: "",
      production_line_description: "",
      ValmetID: "",
      MillID: "",
      Production_line_ID: "",
      FlowControlID: "",
      AFRY_Mill_No: "",
      Default_Mechanical_FL: "",
      Default_Automation_FL: "",
      Default_Electrical_FL: "",
      Default_Instrumenation_FL: "",
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = methods;

  const organizationId = watch("organization_id");

  useEffect(() => {
    if (organizationId !== null) {
      const organization = fetchedData.find(
        (item) => item.id === organizationId
      );
      setValue("company", organization?.company || "", {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await sessionWrapper(getData, getUrl);
      setFetchedData(response);
    };

    if (createProductionLine) {
      fetchData();
    }
  }, [createProductionLine, getUrl]);

  const onSubmit: SubmitHandler<CreateProductionLineInputs> = async (data) => {
    console.log(data);
    setDisabled(true);
    setDropdownActive(false);

    const productionLineData: CreateData = {
      company: data.company,
      organization_id: data.organization_id,
      production_line_user_group_ID: data.production_line_user_group_ID,
      production_line_description: data.production_line_description,
      ValmetID: data.ValmetID || null,
      MillID: data.MillID || null,
      Production_line_ID: data.Production_line_ID || null,
      FlowControlID: data.FlowControlID || null,
      AFRY_Mill_No: data.AFRY_Mill_No || null,
      Default_Mechanical_FL: data.Default_Mechanical_FL || null,
      Default_Automation_FL: data.Default_Automation_FL || null,
      Default_Electrical_FL: data.Default_Electrical_FL || null,
      Default_Instrumenation_FL: data.Default_Instrumenation_FL || null,
    };

    console.log({ productionLineData });

    const response: any = await sessionWrapper(
      postData,
      POST,
      postUrl,
      productionLineData
    );
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    setActive(false);
    reset();
    setDisabled(false);
  };

  useEffect(() => {
    console.log({ setReload, setActive });
  }, [setActive, setReload]);
  return (
    <FormProvider {...methods}>
      <form id="create-production-line-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <div className="control">
            <Dropdown
              dropdownTriggerText={t("Select company")}
              data={fetchedData}
              reset={resetDropdown}
              setReset={setResetDropdown}
              setValue={setValue}
              fieldName="organization_id"
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Company")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add company")}
              readOnly={true}
              {...register("company", { required: true })}
            />
            {errors.company && (
              <p className="has-text-danger">{t("This field is required")}</p>
            )}
            <input type="hidden" {...register("organization_id")} />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Production line user group ID")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add production line user group ID")}
              {...register("production_line_user_group_ID", {
                required: true,
                pattern: VALID_GROUP_NAME_REGEX,
              })}
            />
            {errors.production_line_user_group_ID && (
              <p className="has-text-danger">
                {t("You cannot use spaces in production_line_user_group_ID")}
              </p>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Production line description")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add Production line description")}
              {...register("production_line_description", { required: true })}
            />
            {errors.production_line_description && (
              <p className="has-text-danger">{t("This field is required")}</p>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label">{t("ValmetID")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add ValmetID")}
              {...register("ValmetID")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("MillID")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add MillID")}
              {...register("MillID")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Production line ID")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add Production line ID")}
              {...register("Production_line_ID")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("FlowControlID")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add FlowControlID")}
              {...register("FlowControlID")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("AFRY Mill No")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add AFRY Mill No")}
              {...register("AFRY_Mill_No")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Default Mechanical FL")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add Default Mechanical FL")}
              {...register("Default_Mechanical_FL")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Default Automation FL")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add Default Automation FL")}
              {...register("Default_Automation_FL")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Default Electrical FL")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add Default Electrical FL")}
              {...register("Default_Electrical_FL")}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Default Instrumenation FL")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add Default Instrumenation FL")}
              {...register("Default_Instrumenation_FL")}
            />
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              className="button"
              type="reset"
              onClick={() => {
                setActive(false);
                reset();
                setDisabled(false);
                setResetDropdown(true);
                setDropdownActive(false);
              }}
            >
              {t("Cancel")}
            </button>
          </div>
          <div className="control">
            <button
              className="button is-primary"
              type="submit"
              disabled={disabled}
            >
              {t("Save changes")}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
