import { FieldErrors, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ORGANIZATION_APP_GROUP_USERS,
  ORGANIZATION_USERS,
  VALID_EMAIL_REGEX,
} from "../constants";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import useStore from "../store";

type UsersTabProps = {
  update: boolean;
  setUpdate: Function;
  setCreateUser: Function;
  user_pool_ID: string;
  company: string;
};

export const UsersTab = ({
  update,
  setUpdate,
  setCreateUser,
  user_pool_ID,
  company,
}: UsersTabProps) => {
  const { t } = useTranslation();
  const setUserPoolID = useStore((state) => state.setUserPoolID);
  const setCompany = useStore((state) => state.setCompany);
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const { fields: firstFields /*, remove: removeFirst */ } = useFieldArray({
    control,
    name: ORGANIZATION_APP_GROUP_USERS,
  });
  const { fields: secondFields, remove: removeSecond } = useFieldArray({
    control,
    name: ORGANIZATION_USERS,
  });

  const [render, setRender] = useState(false);

  useEffect(() => {
    if (render) {
      setRender(false);
    }
  }, [render]);

  return (
    <>
      {firstFields.map((field, index) => {
        // @ts-ignore
        const name = field["name"];
        // @ts-ignore
        const email = field["email"];
        // @ts-ignore
        const cognito_username = field["cognito_username"];
        // @ts-ignore
        const sap_id = field["sap_id"];
        // @ts-ignore
        const Production_lines = field["Production_lines"];
        // @ts-ignore
        const organization_app_groups = field["organization_app_groups"];

        const formErrors = errors as FieldErrors<{
          [ORGANIZATION_APP_GROUP_USERS]: {
            [key: string]: {
              name: string;
              email: string;
              cognito_username: string;
              sap_id: string;
              organization_app_groups: [];
            };
          };
        }>;

        return (
          <div key={field.id}>
            <div className="columns">
              <div className="column is-3">
                <div className="field">
                  <label className="label">{t("Name")}</label>
                  <div className="control">
                    {/* READ */}
                    <input
                      className={update ? "input is-hidden" : "input"}
                      type="text"
                      defaultValue={name}
                      readOnly={true}
                      onClick={() => setUpdate(true)}
                    />
                    {/* UPDATE */}
                    <input
                      className={update ? "input" : "input is-hidden"}
                      type="text"
                      {...register(
                        `${ORGANIZATION_APP_GROUP_USERS}.${index}.name`,
                        {
                          required: true,
                        }
                      )}
                    />
                    {formErrors?.[ORGANIZATION_APP_GROUP_USERS]?.[index]
                      ?.name && (
                      <p className="has-text-danger">
                        {t("This field is required")}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <div className="field">
                  <label className="label">{t("Email")}</label>
                  <div className="control">
                    {/* READ */}
                    <input
                      className={update ? "input is-hidden" : "input"}
                      type="text"
                      defaultValue={email}
                      readOnly={true}
                      onClick={() => setUpdate(true)}
                    />
                    {/* UPDATE */}
                    <input
                      className={update ? "input" : "input is-hidden"}
                      type="text"
                      {...register(
                        `${ORGANIZATION_APP_GROUP_USERS}.${index}.email`,
                        {
                          required: true,
                          pattern: {
                            value: VALID_EMAIL_REGEX,
                            message: "",
                          },
                        }
                      )}
                    />
                    {formErrors?.[ORGANIZATION_APP_GROUP_USERS]?.[index]
                      ?.email && (
                      <p className="has-text-danger">
                        {t("This field must match with pattern", {
                          pattern: VALID_EMAIL_REGEX,
                        })}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <div className="field">
                  <label className="label">{t("cognito_username")}</label>
                  <div className="control">
                    {/* READ */}
                    <input
                      className="input"
                      type="text"
                      value={cognito_username}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <div className="field">
                  <label className="label">{t("SAP ID")}</label>
                  <div className="control">
                    {/* READ */}
                    <input
                      className={update ? "input is-hidden" : "input"}
                      type="text"
                      defaultValue={sap_id}
                      readOnly={true}
                      onClick={() => setUpdate(true)}
                    />
                    {/* UPDATE */}
                    <input
                      className={update ? "input" : "input is-hidden"}
                      type="text"
                      {...register(
                        `${ORGANIZATION_APP_GROUP_USERS}.${index}.sap_id`
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <label className="label">{t("Groups")}</label>
                  <div className="control">
                    {organization_app_groups &&
                      organization_app_groups.length > 0 &&
                      organization_app_groups.map(
                        (
                          group: {
                            name:
                              | string
                              | number
                              | boolean
                              | ReactElement<
                                  any,
                                  string | JSXElementConstructor<any>
                                >
                              | Iterable<ReactNode>
                              | ReactPortal
                              | Iterable<ReactNode>
                              | null
                              | undefined;
                            description:
                              | string
                              | number
                              | boolean
                              | ReactElement<
                                  any,
                                  string | JSXElementConstructor<any>
                                >
                              | Iterable<ReactNode>
                              | ReactPortal
                              | Iterable<ReactNode>
                              | null
                              | undefined;
                          },
                          innerIndex: number
                        ) => (
                          <button
                            key={innerIndex}
                            className="button is-primary is-small mr-3"
                            type="button"
                            disabled={true}
                          >
                            {group.name}
                          </button>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  {Production_lines && Production_lines.length > 0 && (
                    <label className="label">{t("Production Lines")}</label>
                  )}
                  <div className="control">
                    {Production_lines &&
                      Production_lines.length > 0 &&
                      Production_lines.map(
                        (
                          line: {
                            active: boolean;
                            production_line_id: number;
                            production_line_user_group_ID: string;
                          },
                          innerIndex: number
                        ) => (
                          <button
                            key={innerIndex}
                            className={
                              line.active
                                ? "button is-primary is-small mr-3"
                                : "button is-light is-small mr-3"
                            }
                            type="button"
                            disabled={update ? false : true}
                            onClick={() => {
                              line.active = !line.active;
                              setRender(true);
                            }}
                          >
                            <span className="icon">
                              <i
                                className={
                                  line.active
                                    ? "mdi mdi-checkbox-marked-outline"
                                    : "mdi mdi-checkbox-blank-outline"
                                }
                              ></i>
                            </span>
                            <span>{line.production_line_user_group_ID}</span>
                          </button>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <div className="control">
                    <button
                      className={
                        update
                          ? "button is-danger is-pulled-right"
                          : "button is-danger is-pulled-right is-hidden"
                      }
                      type="button"
                      onClick={() => removeFirst(index)}
                      disabled={true}
                    >
                      {t("Delete user")}
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            <hr />
          </div>
        );
      })}
      {secondFields.map((field, index) => {
        // @ts-ignore
        const name = field["name"];
        // @ts-ignore
        const email = field["email"];
        // @ts-ignore
        const cognito_username = field["cognito_username"];
        // @ts-ignore
        const sap_id = field["sap_id"];
        // @ts-ignore
        const Production_lines = field["Production_lines"];

        const formErrors = errors as FieldErrors<{
          [ORGANIZATION_USERS]: {
            [key: string]: {
              name: string;
              email: string;
              cognito_username: string;
              sap_id: string;
            };
          };
        }>;

        return (
          <div key={field.id}>
            <div className="columns">
              <div className="column is-3">
                <div className="field">
                  <label className="label">{t("Name")}</label>
                  <div className="control">
                    {/* READ */}
                    <input
                      className={update ? "input is-hidden" : "input"}
                      type="text"
                      defaultValue={name}
                      readOnly={true}
                      onClick={() => setUpdate(true)}
                    />
                    {/* UPDATE */}
                    <input
                      className={update ? "input" : "input is-hidden"}
                      type="text"
                      {...register(`${ORGANIZATION_USERS}.${index}.name`, {
                        required: true,
                      })}
                    />
                    {formErrors?.[ORGANIZATION_USERS]?.[index]?.name && (
                      <p className="has-text-danger">
                        {t("This field is required")}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <div className="field">
                  <label className="label">{t("Email")}</label>
                  <div className="control">
                    {/* READ */}
                    <input
                      className={update ? "input is-hidden" : "input"}
                      type="text"
                      defaultValue={email}
                      readOnly={true}
                      onClick={() => setUpdate(true)}
                    />
                    {/* UPDATE */}
                    <input
                      className={update ? "input" : "input is-hidden"}
                      type="text"
                      {...register(`${ORGANIZATION_USERS}.${index}.email`, {
                        required: true,
                        pattern: {
                          value: VALID_EMAIL_REGEX,
                          message: "",
                        },
                      })}
                    />
                    {formErrors?.[ORGANIZATION_USERS]?.[index]?.email && (
                      <p className="has-text-danger">
                        {t("This field must match with pattern", {
                          pattern: VALID_EMAIL_REGEX,
                        })}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <div className="field">
                  <label className="label">{t("cognito_username")}</label>
                  <div className="control">
                    {/* READ */}
                    <input
                      className="input"
                      type="text"
                      value={cognito_username}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <div className="field">
                  <label className="label">{t("SAP ID")}</label>
                  <div className="control">
                    {/* READ */}
                    <input
                      className={update ? "input is-hidden" : "input"}
                      type="text"
                      defaultValue={sap_id}
                      readOnly={true}
                      onClick={() => setUpdate(true)}
                    />
                    {/* UPDATE */}
                    <input
                      className={update ? "input" : "input is-hidden"}
                      type="text"
                      {...register(`${ORGANIZATION_USERS}.${index}.sap_id`)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  {Production_lines && Production_lines.length > 0 && (
                    <label className="label">{t("Production Lines")}</label>
                  )}
                  <div className="control">
                    {Production_lines &&
                      Production_lines.length > 0 &&
                      Production_lines.map(
                        (
                          line: {
                            active: boolean;
                            production_line_id: number;
                            production_line_user_group_ID: string;
                          },
                          innerIndex: number
                        ) => (
                          <button
                            key={innerIndex}
                            className={
                              line.active
                                ? "button is-primary is-small mr-3"
                                : "button is-light is-small mr-3"
                            }
                            type="button"
                            disabled={update ? false : true}
                            onClick={() => {
                              line.active = !line.active;
                              setRender(true);
                            }}
                          >
                            <span className="icon">
                              <i
                                className={
                                  line.active
                                    ? "mdi mdi-checkbox-marked-outline"
                                    : "mdi mdi-checkbox-blank-outline"
                                }
                              ></i>
                            </span>
                            <span>{line.production_line_user_group_ID}</span>
                          </button>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <div className="control">
                    <button
                      className={
                        update
                          ? "button is-danger is-pulled-right"
                          : "button is-danger is-pulled-right is-hidden"
                      }
                      type="button"
                      onClick={() => removeSecond(index)}
                    >
                      {t("Delete user")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        );
      })}
      <div className="columns">
        <div className="column is-12">
          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button
                className="button is-primary"
                type="button"
                disabled={false}
                onClick={() => {
                  setUserPoolID(user_pool_ID);
                  setCompany(company);
                  setCreateUser(true);
                }}
              >
                {t("Add new user")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
