import { useState } from "react";

interface MainContainerProps {
  name: string;
  update: boolean;
  setUpdate: Function;
  children: React.ReactNode;
}

export const MainContainer = ({
  name,
  update,
  setUpdate,
  children,
}: MainContainerProps) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="card mt-3 mr-3">
      <header className="card-header">
        <p className="card-header-title">{name}</p>
        <button
          onClick={() => {
            setExpanded(!expanded);
            if (update) setUpdate(false);
          }}
          className="card-header-icon"
          aria-label="more options"
        >
          <span className="icon">
            <i
              className={
                expanded ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"
              }
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </header>
      <div className={expanded ? "card-content" : "card-content is-hidden"}>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};
