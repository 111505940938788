import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { postData } from "../dal/fetchRequest";
import {
  BACKEND_ORGANIZATIONS,
  VALID_COMPANY_PATTERN_REGEX,
  POST,
} from "../constants";
import useStore from "../store";
import { useTranslation } from "react-i18next";
import { sessionWrapper } from "../dal/sessionWarpper";

type CreateCompanyFormProps = {
  setReload: Function;
  setActive: Function;
};

type CreateCompanyInputs = {
  company: string;
  company_name: string;
  site: string;
};

type CreateData = {
  company: string;
  company_name: string;
  site: string;
  user_pool_name: string;
};

export const CreateCompanyForm = ({
  setReload,
  setActive,
}: CreateCompanyFormProps) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_ORGANIZATIONS}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateCompanyInputs>();

  const onSubmit: SubmitHandler<CreateCompanyInputs> = async (data) => {
    console.log(data);
    setDisabled(true);

    const companyData: CreateData = {
      company: data.company,
      company_name: data.company_name,
      site: data.site,
      user_pool_name: data.company, // Userpool name is salted in backend
    };

    console.log({ companyData });

    const response: any = await sessionWrapper(
      postData,
      POST,
      url,
      companyData
    );
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    setActive(false);
    reset();
    setDisabled(false);
  };

  return (
    <form id="create-company-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        <label className="label">{t("Company")}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder={t("Short company name for end users logon purposes")}
            {...register("company", {
              required: true,
              pattern: VALID_COMPANY_PATTERN_REGEX,
            })}
          />
          {errors.company && (
            <p className="has-text-danger">
              {t("This field must match with pattern", {
                pattern: VALID_COMPANY_PATTERN_REGEX,
              })}
            </p>
          )}
        </div>
      </div>

      <div className="field">
        <label className="label">{t("Company name")}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder={t("Company name from Valmet Compass")}
            {...register("company_name", { required: true })}
          />
          {errors.company_name && (
            <p className="has-text-danger">{t("This field is required")}</p>
          )}
        </div>
      </div>

      <div className="field">
        <label className="label">{t("Site")}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder={t("Site from Valmet Compass")}
            {...register("site", { required: true })}
          />
          {errors.site && (
            <p className="has-text-danger">{t("This field is required")}</p>
          )}
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button"
            type="reset"
            onClick={() => {
              setActive(false);
              reset();
              setDisabled(false);
            }}
          >
            {t("Cancel")}
          </button>
        </div>
        <div className="control">
          <button
            className="button is-primary"
            type="submit"
            disabled={disabled}
          >
            {t("Save changes")}
          </button>
        </div>
      </div>
    </form>
  );
};
