import { useTranslation } from "react-i18next";
import { BACKEND_GROUPS, POST, VALID_GROUP_NAME_REGEX } from "../constants";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import useStore from "../store";
import { postData } from "../dal/fetchRequest";
import { sessionWrapper } from "../dal/sessionWarpper";

type AddGroupToApplicationFormProps = {
  setReload: Function;
  setActive: Function;
};

type CreateGroupInputs = {
  group_name: string;
  group_description: string;
};

type CreateData = {
  name: string;
  description: string;
  app_id: number | null;
};

export const AddGroupToApplicationForm = ({
  setReload,
  setActive,
}: AddGroupToApplicationFormProps) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_GROUPS}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const idAndName = useStore((state) => state.idAndName);
  const resetIdAndName = useStore((state) => state.resetIdAndName);
  const methods = useForm<CreateGroupInputs>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<CreateGroupInputs> = async (data) => {
    console.log(data);
    setDisabled(true);

    const groupData: CreateData = {
      name: data.group_name,
      description: data.group_description,
      app_id: idAndName.id,
    };

    console.log({ groupData });

    const response: any = await sessionWrapper(postData, POST, url, groupData);
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    setActive(false);
    reset();
    setDisabled(false);
    resetIdAndName();
  };

  return (
    <form id="create-application-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        <label className="label">{t("Group name")}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder={t("Add group name")}
            {...register("group_name", {
              required: true,
              pattern: VALID_GROUP_NAME_REGEX,
            })}
          />
          {errors.group_name && (
            <p className="has-text-danger">
              {t("You cannot use spaces in the group name")}
            </p>
          )}
        </div>
      </div>

      <div className="field">
        <label className="label">{t("Group description")}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder={t("Add group description")}
            {...register("group_description")}
          />
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button"
            type="reset"
            onClick={() => {
              setActive(false);
              reset();
              setDisabled(false);
            }}
          >
            {t("Cancel")}
          </button>
        </div>
        <div className="control">
          <button
            className="button is-primary"
            type="submit"
            disabled={disabled}
          >
            {t("Save changes")}
          </button>
        </div>
      </div>
    </form>
  );
};
