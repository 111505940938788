import { FieldErrors, useFieldArray, useFormContext } from "react-hook-form";
import {
  KEY,
  KEY_VALUES,
  VALID_PROPERTY_NAME_REGEX,
  VALUE,
} from "../constants";
import { useTranslation } from "react-i18next";

export const CreateKeyValuesForm = () => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: KEY_VALUES,
  });

  return (
    <>
      {fields.map((field, index) => {
        const formErrors = errors as FieldErrors<{
          [KEY_VALUES]: { [key: string]: { [KEY]: string; [VALUE]: string } };
        }>;

        return (
          <div className="columns" key={field.id}>
            <div className="column is-4">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder={t("Add key")}
                    {...register(`${KEY_VALUES}.${index}.${KEY}`, {
                      required: true,
                      pattern: VALID_PROPERTY_NAME_REGEX,
                    })}
                  />
                  {formErrors?.[KEY_VALUES]?.[index]?.[KEY] && (
                    <p className="has-text-danger">
                      {t("This field must match with pattern", {
                        pattern: VALID_PROPERTY_NAME_REGEX,
                      })}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="column is-6">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder={t("Add value")}
                    {...register(`${KEY_VALUES}.${index}.${VALUE}`, {
                      required: true,
                    })}
                  />
                  {formErrors?.[KEY_VALUES]?.[index]?.[VALUE] && (
                    <p className="has-text-danger">
                      {t("This field is required")}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="column is-2">
              <div className="field">
                <div className="control">
                  <button
                    className="button is-small is-danger is-pulled-right"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    {t("Delete")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <button
        className="button is-small is-primary"
        type="button"
        onClick={() => append({ [KEY]: "", [VALUE]: "" })}
      >
        {t("Add key values")}
      </button>
    </>
  );
};
