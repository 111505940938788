import { useEffect, useState } from "react";
import { sessionWrapper } from "../dal/sessionWarpper";
import { getData } from "../dal/fetchRequest";
import { BACKEND_CUSTOM_OVERRIDES, GET } from "../constants";
import { KeyValues } from "./types";
import useStore from "../store";
import { useTranslation } from "react-i18next";

type CustomOverridesProps = {
  customOverrides: boolean;
};

type FetchedDataType = {
  company: string;
  company_name: string;
  app_id: string;
  name: string;
  custom_key_values: KeyValues[];
};

export const CustomOverrides = ({ customOverrides }: CustomOverridesProps) => {
  const { t } = useTranslation();
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_CUSTOM_OVERRIDES}`;
  const globalCustomOverrides = useStore(
    (state) => state.globalCustomOverrides
  );
  const resetGlobalCustomOverrides = useStore(
    (state) => state.resetGlobalCustomOverrides
  );
  const [fetchedData, setFetchedData] = useState<FetchedDataType[] | null>(
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await sessionWrapper(
        getData,
        `${url}?id=${globalCustomOverrides.id}`
      );
      console.log({ response });
      // getData response handling should be refactored...
      if (Array.isArray(response)) {
        resetGlobalError();
        setFetchedData(response);
      } else if (response.status !== 200) {
        const errorMessage = `${GET} ${url}?id=${globalCustomOverrides.id} ${response?.status} (${response?.response})`;
        setGlobalError(errorMessage);
      }
    };

    if (customOverrides && globalCustomOverrides) fetchData();

    return () => {
      if (
        globalCustomOverrides &&
        globalCustomOverrides.id &&
        globalCustomOverrides.name &&
        globalCustomOverrides.app_id
      ) {
        resetGlobalCustomOverrides();
      }
    };
  }, [
    customOverrides,
    globalCustomOverrides,
    resetGlobalCustomOverrides,
    resetGlobalError,
    setGlobalError,
    url,
  ]);

  const handleCustomOverrides = (fetchedData: FetchedDataType[]) => {
    return (
      fetchedData &&
      fetchedData.length > 0 &&
      fetchedData.map((customOverride, index) => (
        <div key={index}>
          <div className="columns">
            <div className="column is-half">
              <div className="field">
                <label className="label">{t("Company")}</label>
                <div className="control">
                  {/* READ ONLY */}
                  <input
                    className="input"
                    type="text"
                    defaultValue={customOverride.company}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="field">
                <label className="label">{t("Company name")}</label>
                <div className="control">
                  {/* READ ONLY */}
                  <input
                    className="input"
                    type="text"
                    defaultValue={customOverride.company_name}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-half">
              <div className="field">
                <label className="label">{t("Application ID")}</label>
                <div className="control">
                  {/* READ ONLY */}
                  <input
                    className="input"
                    type="text"
                    defaultValue={customOverride.app_id}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="field">
                <label className="label">{t("Application name")}</label>
                <div className="control">
                  {/* READ ONLY */}
                  <input
                    className="input"
                    type="text"
                    defaultValue={customOverride.name}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-full">
              <div className="field">
                <label className="label">{t("Custom key values")}</label>
              </div>
            </div>
          </div>
          {customOverride.custom_key_values &&
            customOverride.custom_key_values.length > 0 &&
            customOverride.custom_key_values.map((keyValue, index) => (
              <div key={index} className="columns">
                <div className="column is-half">
                  <div className="field">
                    <div className="control">
                      {/* READ ONLY */}
                      <input
                        className="input"
                        type="text"
                        defaultValue={keyValue.key}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-half">
                  <div className="field">
                    <div className="control">
                      {/* READ ONLY */}
                      <input
                        className="input"
                        type="text"
                        defaultValue={keyValue.value}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <hr />
        </div>
      ))
    );
  };

  return (
    <>
      {fetchedData && fetchedData.length > 0 ? (
        handleCustomOverrides(fetchedData)
      ) : (
        <>{t("No custom overrides found")}</>
      )}
    </>
  );
};
