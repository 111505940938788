import { FieldErrors, useFieldArray, useFormContext } from "react-hook-form";
import {
  KEY,
  KEY_VALUES,
  VALID_PROPERTY_NAME_REGEX,
  VALUE,
} from "../constants";
import { useTranslation } from "react-i18next";

type UpdateKeyValuesProps = {
  update: boolean;
  setUpdate: Function;
};

export const UpdateKeyValuesForm = ({
  update,
  setUpdate,
}: UpdateKeyValuesProps) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: KEY_VALUES,
  });

  return (
    <>
      {fields.map((field, index) => {
        // @ts-ignore
        const key = field[KEY];
        // @ts-ignore
        const value = field[VALUE];

        const formErrors = errors as FieldErrors<{
          [KEY_VALUES]: { [key: string]: { [KEY]: string; [VALUE]: string } };
        }>;

        return (
          <div className="columns" key={field.id}>
            <div className="column is-4">
              <div className="field">
                <div className="control">
                  {/* READ */}
                  <input
                    className={update ? "input is-hidden" : "input"}
                    type="text"
                    defaultValue={key}
                    readOnly={true}
                    onClick={() => setUpdate(true)}
                  />
                  {/* UPDATE */}
                  <input
                    className={update ? "input" : "input is-hidden"}
                    type="text"
                    placeholder={t("Add key")}
                    {...register(`${KEY_VALUES}.${index}.${KEY}`, {
                      required: true,
                      pattern: VALID_PROPERTY_NAME_REGEX,
                    })}
                  />
                  {formErrors?.[KEY_VALUES]?.[index]?.[KEY] && (
                    <p className="has-text-danger">
                      {t("This field must match with pattern", {
                        pattern: VALID_PROPERTY_NAME_REGEX,
                      })}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="column is-6">
              <div className="field">
                <div className="control">
                  {/* READ */}
                  <input
                    className={update ? "input is-hidden" : "input"}
                    type="text"
                    defaultValue={value}
                    placeholder={t("Add value")}
                    onClick={() => setUpdate(true)}
                  />
                  {/* UPDATE */}
                  <input
                    className={update ? "input" : "input is-hidden"}
                    type="text"
                    placeholder={t("Add value")}
                    {...register(`${KEY_VALUES}.${index}.${VALUE}`, {
                      required: true,
                    })}
                  />
                  {formErrors?.[KEY_VALUES]?.[index]?.[VALUE] && (
                    <p className="has-text-danger">
                      {t("This field is required")}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="column is-2">
              <div className="field">
                <div className="control">
                  <button
                    className={
                      update
                        ? "button is-small is-danger is-pulled-right"
                        : "button is-small is-danger is-pulled-right is-hidden"
                    }
                    type="button"
                    onClick={() => remove(index)}
                  >
                    {t("Delete")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <button
        className="button is-small is-primary"
        type="button"
        disabled={!update}
        onClick={() => {
          setUpdate(true);
          append({ [KEY]: "", [VALUE]: "" });
        }}
      >
        {t("Add key values")}
      </button>
    </>
  );
};
