import React from "react";
import ReactDOM from "react-dom/client";
import Companies from "./companies";
import reportWebVitals from "./reportWebVitals";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "./login";
import {
  FRONTEND_APPLICATIONS,
  FRONTEND_COMPANIES,
  FRONTEND_ROOT,
  FRONTEND_USERS,
  FRONTEND_PRODUCTION_LINES,
} from "./constants";
import { ProtectedRoute } from "./protectedRoute";
import "./i18n";
import { FRONTEND_TEST_VERSION } from "./constants";
import "@mdi/font/css/materialdesignicons.css";
import "./styles/index.sass";
import { Wrapper, getEnvironment } from "./wrapper";
import Applications from "./applications";
import Users from "./users";
import ProductionLines from "./productionLines";

const App: React.FC = () => {
  console.log(getEnvironment());
  console.log({ FRONTEND_TEST_VERSION }); // #remeber-to-update-stage-version
  return (
    <BrowserRouter>
      <Routes>
        <Route path={FRONTEND_ROOT} element={<Login />} />
        <Route
          path={FRONTEND_COMPANIES}
          element={
            <ProtectedRoute>
              <Wrapper>
                <Companies />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={FRONTEND_APPLICATIONS}
          element={
            <ProtectedRoute>
              <Wrapper>
                <Applications />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={FRONTEND_PRODUCTION_LINES}
          element={
            <ProtectedRoute>
              <Wrapper>
                <ProductionLines />
              </Wrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path={FRONTEND_USERS}
          element={
            <ProtectedRoute>
              <Wrapper>
                <Users />
              </Wrapper>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
