/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

interface TabsProps {
  tabs: string[];
  content: React.ReactNode[];
}

export const Tabs = ({ tabs, content }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const onClickTabItem = (tab: number) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="tabs is-boxed is-fullwidth">
        <ul className="ml-0">
          {tabs.map((tab, index) => {
            return (
              <li
                className={activeTab === index ? "is-active" : ""}
                key={index}
                onClick={() => onClickTabItem(index)}
              >
                <a>{tab}</a>
              </li>
            );
          })}
        </ul>
      </div>
      {content.map((item, index) => {
        return (
          <div
            className={activeTab === index ? "is-active" : "is-hidden"}
            key={index}
          >
            {item}
          </div>
        );
      })}
    </>
  );
};
