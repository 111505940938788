import { useTranslation } from "react-i18next";
import { Dropdown } from "../components/dropDown";
import { Application } from "./joinUserToGroupForm";
import useStore from "../store";
import { useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import {
  APPLICATIONS,
  BACKEND_APP_GROUPS_AND_ORGANIZATION_APPS,
  BACKEND_ORGANIZATION_WITH_APPS,
  PUT,
} from "../constants";
import { sessionWrapper } from "../dal/sessionWarpper";
import { getData, postData } from "../dal/fetchRequest";

type JoinApplicationToCompanyFormProps = {
  setReload: Function;
  joinApplication: boolean;
  setActive: Function;
};

type JoinApplicationToCompanyInputs = {
  applications: Application[];
};

type Incoming = {
  organization_id: number | null;
  applications: Application[];
};

type Current = {
  organization_id: number | null;
  applications: Application[];
};

type OrganizationWithApps = {
  incoming: Incoming;
  current: Current;
};

type FetchedDataType = {
  free_apps: Application[];
  apps: Application[];
};

export const JoinApplicationToCompanyForm = ({
  setReload,
  joinApplication,
  setActive,
}: JoinApplicationToCompanyFormProps) => {
  const { t } = useTranslation();
  const id = useStore((state) => state.id);
  const getUrl = `${process.env.REACT_APP_BACKEND}${BACKEND_APP_GROUPS_AND_ORGANIZATION_APPS}?organization_id=${id}`;
  const putUrl = `${process.env.REACT_APP_BACKEND}${BACKEND_ORGANIZATION_WITH_APPS}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const resetCompany = useStore((state) => state.resetCompany);
  const resetId = useStore((state) => state.resetId);
  const [disabled, setDisabled] = useState(false);
  const setDropdownActive = useStore((state) => state.setDropdownActive);
  const [reset, setReset] = useState(false);
  const [fetchedData, setFetchedData] = useState<FetchedDataType | null>(null);
  const [freeApps, setFreeApps] = useState<Application[]>([]);
  const [apps, setApps] = useState<Application[]>([]);
  const { handleSubmit, setValue, control } =
    useForm<JoinApplicationToCompanyInputs>({
      defaultValues: {
        applications: [],
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: APPLICATIONS,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await sessionWrapper(getData, getUrl);
      setFetchedData(response);
    };

    if (joinApplication) {
      fetchData();
    }
  }, [joinApplication, getUrl]);

  useEffect(() => {
    if (fetchedData) {
      setFreeApps(fetchedData.free_apps);
      setApps(fetchedData.apps);
      setValue(APPLICATIONS, fetchedData.apps);
    }
  }, [fetchedData, setValue]);

  const onSubmit: SubmitHandler<JoinApplicationToCompanyInputs> = async (
    data
  ) => {
    console.log(data);
    setDisabled(true);
    setDropdownActive(false);

    const organizationWithApps: OrganizationWithApps = {
      incoming: {
        organization_id: id,
        applications: data.applications,
      },
      current: {
        organization_id: id,
        applications: apps,
      },
    };

    console.log({ organizationWithApps });

    const response: any = await sessionWrapper(
      postData,
      PUT,
      putUrl,
      organizationWithApps
    );
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    setActive(false);
    setValue(APPLICATIONS, apps);
    resetCompany();
    resetId();
    setDisabled(false);
  };

  return (
    <form
      id="join-application-to-company-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="field">
            <div className="control">
              {fetchedData && freeApps && (
                <Dropdown
                  dropdownTriggerText={t("Select application")}
                  data={freeApps}
                  append={append}
                  reset={reset}
                  setReset={setReset}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-5">
          <div className="field">
            <label className="label">{t("Application name")}</label>
          </div>
        </div>
        <div className="column is-5">
          <div className="field">
            <label className="label">{t("Application ID")}</label>
          </div>
        </div>
        <div className="column is-2">
          <div className="field">
            <label className="label"></label>
          </div>
        </div>
      </div>

      {fields.map((field, index) => {
        // @ts-ignore
        const name = field["name"];
        // @ts-ignore
        const app_id = field["app_id"];

        return (
          <div key={field.id}>
            <div className="columns">
              <div className="column is-5">
                <div className="field">
                  <div className="control">
                    {/* READ ONLY */}
                    <input
                      className="input"
                      type="text"
                      defaultValue={name}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-5">
                <div className="field">
                  <div className="control">
                    {/* READ ONLY */}
                    <input
                      className="input"
                      type="text"
                      defaultValue={app_id}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-2">
                <div className="field">
                  <div className="control">
                    <button
                      className="button is-small is-danger is-pulled-right"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <span className="icon">
                        <i className="mdi mdi-link-off" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <hr />
      <div className="columns">
        <div className="column is-12">
          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button
                className="button"
                type="reset"
                onClick={() => {
                  setActive(false);
                  setValue(APPLICATIONS, apps);
                  setReset(true);
                  setDisabled(false);
                  setDropdownActive(false);
                }}
              >
                {t("Cancel")}
              </button>
            </div>
            <div className="control">
              <button
                className="button is-primary"
                type="submit"
                disabled={disabled}
              >
                {t("Save changes")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
