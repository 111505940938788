import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  BACKEND_JOIN_USER_TO_GROUP,
  BACKEND_ORGANIZATION_USERS_AND_GROUP_USERS,
  ORGANIZATION_USERS,
  PUT,
} from "../constants";
import { Dropdown } from "../components/dropDown";
import useStore from "../store";
import { sessionWrapper } from "../dal/sessionWarpper";
import { getData, postData } from "../dal/fetchRequest";

type JoinUserToGroupFormProps = {
  setReload: Function;
  joinUser: boolean;
  setActive: Function;
};

export type OrganizationUser = {
  name: string;
  email: string;
  cognito_username: string;
  sap_id: string;
};

export type Application = {
  name: string;
  app_id: string;
};

type User = {
  name: string;
  email: string;
  cognito_username: string;
  sap_id: string;
};

type JoinUserToGroupInputs = {
  organization_users: OrganizationUser[];
};

type Incoming = {
  users: User[];
};

type Current = {
  group_id: number | null;
  users: User[];
};

type GroupWithUsers = {
  organization_id: number | null;
  incoming: Incoming;
  current: Current;
};

type FetchedDataType = {
  free_users: User[];
  users: User[];
};

export const JoinUserToGroupForm = ({
  setReload,
  joinUser,
  setActive,
}: JoinUserToGroupFormProps) => {
  const { t } = useTranslation();
  const id = useStore((state) => state.id);
  const groupId = useStore((state) => state.groupId);
  const getUrl = `${process.env.REACT_APP_BACKEND}${BACKEND_ORGANIZATION_USERS_AND_GROUP_USERS}?organization_id=${id}&group_id=${groupId}`;
  const putUrl = `${process.env.REACT_APP_BACKEND}${BACKEND_JOIN_USER_TO_GROUP}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const resetGroupName = useStore((state) => state.resetGroupName);
  const resetGroupId = useStore((state) => state.resetGroupId);
  const resetId = useStore((state) => state.resetId);
  const [disabled, setDisabled] = useState(false);
  const setDropdownActive = useStore((state) => state.setDropdownActive);
  const [reset, setReset] = useState(false);
  const [fetchedData, setFetchedData] = useState<FetchedDataType | null>(null);
  const [freeUsers, setFreeUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const { handleSubmit, setValue, control } = useForm<JoinUserToGroupInputs>({
    defaultValues: {
      organization_users: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: ORGANIZATION_USERS,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await sessionWrapper(getData, getUrl);
      setFetchedData(response);
    };

    if (joinUser) {
      fetchData();
    }
  }, [joinUser, getUrl]);

  useEffect(() => {
    if (fetchedData) {
      setFreeUsers(fetchedData.free_users);
      setUsers(fetchedData.users);
      setValue(ORGANIZATION_USERS, fetchedData.users);
    }
  }, [fetchedData, setValue]);

  const onSubmit: SubmitHandler<JoinUserToGroupInputs> = async (data) => {
    console.log(data);
    setDisabled(true);
    setDropdownActive(false);

    const groupWithUsers: GroupWithUsers = {
      organization_id: id,
      incoming: {
        users: data.organization_users,
      },
      current: {
        group_id: groupId,
        users: users,
      },
    };

    console.log({ groupWithUsers });

    const response: any = await sessionWrapper(
      postData,
      PUT,
      putUrl,
      groupWithUsers
    );
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    setActive(false);
    setValue(ORGANIZATION_USERS, users);
    resetGroupName();
    resetGroupId();
    resetId();
    setDisabled(false);
  };

  return (
    <form id="join-user-to-group-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="field">
            <div className="control">
              {fetchedData && freeUsers && (
                <Dropdown
                  dropdownTriggerText={t("Select user")}
                  data={freeUsers}
                  append={append}
                  reset={reset}
                  setReset={setReset}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-3">
          <div className="field">
            <label className="label">{t("Name")}</label>
          </div>
        </div>
        <div className="column is-3">
          <div className="field">
            <label className="label">{t("Email")}</label>
          </div>
        </div>
        <div className="column is-2">
          <div className="field">
            <label className="label">{t("cognito_username_short")}</label>
          </div>
        </div>
        <div className="column is-3">
          <div className="field">
            <label className="label">{t("SAP ID")}</label>
          </div>
        </div>
        <div className="column is-1">
          <div className="field">
            <label className="label"></label>
          </div>
        </div>
      </div>

      {fields.map((field, index) => {
        // @ts-ignore
        const name = field["name"];
        // @ts-ignore
        const email = field["email"];
        // @ts-ignore
        const cognito_username = field["cognito_username"];
        // @ts-ignore
        const sap_id = field["sap_id"];

        return (
          <div key={field.id}>
            <div className="columns">
              <div className="column is-3">
                <div className="field">
                  <div className="control">
                    {/* READ ONLY */}
                    <input
                      className="input"
                      type="text"
                      defaultValue={name}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <div className="field">
                  <div className="control">
                    {/* READ ONLY */}
                    <input
                      className="input"
                      type="text"
                      defaultValue={email}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-2">
                <div className="field">
                  <div className="control">
                    {/* READ ONLY */}
                    <input
                      className="input"
                      type="text"
                      value={cognito_username}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <div className="field">
                  <div className="control">
                    {/* READ ONLY */}
                    <input
                      className="input"
                      type="text"
                      defaultValue={sap_id}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-1">
                <div className="field">
                  <div className="control">
                    <button
                      className="button is-small is-danger is-pulled-right"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <span className="icon">
                        <i className="mdi mdi-link-off" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <hr />
      <div className="columns">
        <div className="column is-12">
          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button
                className="button"
                type="reset"
                onClick={() => {
                  setActive(false);
                  setValue(ORGANIZATION_USERS, users);
                  setReset(true);
                  setDisabled(false);
                  setDropdownActive(false);
                }}
              >
                {t("Cancel")}
              </button>
            </div>
            <div className="control">
              <button
                className="button is-primary"
                type="submit"
                disabled={disabled}
              >
                {t("Save changes")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
