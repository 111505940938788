import { SetStateAction, useEffect, useState } from "react";
import {
  ASC,
  BACKEND_FILTERED_USERS,
  BACKEND_PAGED_USERS,
  DESC,
  GET,
  OFF,
} from "../constants";
import useStore from "../store";
import { useNavigate } from "react-router-dom";
import { Table } from "../components/table";
import { useTranslation } from "react-i18next";
import { Paging } from "../components/paging";
import { sessionWrapper } from "../dal/sessionWarpper";
import { getData } from "../dal/fetchRequest";

type UserItem = {
  id: number;
  name: string;
  email: string;
  cognito_username: string;
  sap_id: string;
  company: string;
  apps: string[];
  groups: string[];
  production_lines: string[];
};

type TableItem = {
  id: number;
  name: string;
  email: string;
  cognito_username: string;
  sap_id: string;
  company: string;
  apps: string;
  groups: string;
  production_lines: string;
};

type TableData = TableItem[];

const Users: React.FC = () => {
  const [tableSortingState, setTableSortingState] = useState<string>(OFF);
  let sortingState = OFF;
  const [searchKey, setSearchKey] = useState<string>("");
  const { t } = useTranslation();
  const setGlobalError = useStore((state) => state.setGlobalError);
  const [reload, setReload] = useState(false);
  const [tableData, setTableData] = useState<any | null>(null);
  const [tableHeaderData] = useState([
    { id: t("id") },
    { name: t("name") },
    { email: t("Email") },
    { cognito_username: t("cognito_username") },
    { sap_id: t("SAP ID") },
    { company: t("Company") },
    { apps: t("Applications") },
    { groups: t("Groups") },
    { production_lines: t("Production Lines") },
  ]);
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const limit20 = 20;
  const limit50 = 50;
  const limit100 = 100;
  const [data, setData] = useState<any | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(limit20);
  const [offset, setOffset] = useState<number>(0);
  const [pagingParams, setPagingParams] = useState(
    `?limit=${limit}&offset=${offset}`
  );
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_PAGED_USERS}${pagingParams}`;
  const filteringUrl = `${process.env.REACT_APP_BACKEND}${BACKEND_FILTERED_USERS}`;
  const [previousSearchKey, setPreviousSearchKey] = useState<string>("");
  const [showPaging, setShowPaging] = useState<boolean>(true);

  useEffect(() => {
    const getPagedUsers = async () => {
      const response = await sessionWrapper(getData, url);
      console.log({ response });
      if (response.status >= 400) {
        setGlobalError(
          `${GET} ${response?.url} ${response?.status} (${response?.statusText}, ${response?.message})`
        );
      } else {
        setData(response);
      }
    };
    console.log({ pagingParams, url });
    getPagedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingParams]);

  useEffect(() => {
    if (searchString !== "") {
      if (!searchKey) return;

      console.log({ searchKey, searchString });

      const getFilteredUsers = async () => {
        const response = await sessionWrapper(
          getData,
          `${filteringUrl}?key=${searchKey}&value=${searchString}`
        );
        console.log({ response });
        if (response.status >= 400) {
          setGlobalError(
            `${GET} ${response?.url} ${response?.status} (${response?.statusText}, ${response?.message})`
          );
        } else {
          setData(response);
          setShowPaging(false);
        }
      };
      getFilteredUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, searchString]);

  useEffect(() => {
    if (data && data?.users && data?.users.length > 0) {
      setTotal(data.total);
      setLimit(data.limit);
      setOffset(data.offset);
      const newData: TableData[] = data.users.map((item: UserItem) => {
        const newItem = { ...item } as unknown as TableItem;
        newItem.apps = item.apps.join(", ");
        newItem.groups = item.groups.join(", ");
        newItem.production_lines = item.production_lines.join(", ");
        return newItem;
      });
      setTableData(newData);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    if (reload) {
      navigate(0);
      setReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const ascState = () => {
    setTableSortingState(ASC);
    sortingState = ASC;
  };

  const descState = () => {
    setTableSortingState(DESC);
    sortingState = DESC;
  };

  const offState = () => {
    setTableSortingState(OFF);
    sortingState = OFF;
  };

  const sorter = (column: string, data: any[]) => {
    if (Object.keys(column)[0]) {
      const key = Object.keys(column)[0];
      setSearchKey(key);

      if (data && data?.length > 0) {
        if (previousSearchKey !== "" && previousSearchKey !== key) {
          /**
           * User have clicked on another column,
           * so we need to reset sortingState.
           */
          ascState();
        } else if (tableSortingState === OFF) {
          ascState();
        } else if (tableSortingState === ASC) {
          descState();
        } else if (tableSortingState === DESC) {
          offState();
        }

        if (sortingState === ASC) {
          if (key === "id" || key === "sap_id") {
            data.sort((a, b) => a[key] - b[key]);
          } else {
            data.sort((a, b) => a[key].localeCompare(b[key]));
          }
        } else if (sortingState === DESC) {
          if (key === "id" || key === "sap_id") {
            data.sort((a, b) => b[key] - a[key]);
          } else {
            data.sort((a, b) => b[key].localeCompare(a[key]));
          }
        } else {
          // OFF
          data.sort((a, b) => a.id - b.id);
        }

        setPreviousSearchKey(key);
      }
    }
  };

  const onChangeHandler = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    if (event) {
      if (event.target.value === "") {
        setReload(true);
      }
      setSearchString(event.target.value);
    }
  };

  return (
    <div className="card">
      <div className="card-content">
        <div className="content">
          <form id="search">
            <div className="field is-grouped">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={searchString}
                  placeholder={t("Search...")}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="control">
                <button
                  className="button is-primary"
                  type="button"
                  onClick={() => {
                    setReload(true);
                  }}
                >
                  {t("Clear")}
                </button>
              </div>
            </div>
          </form>
          <br />
          {/* table-container is now working without display grid hack */}
          <div style={{ display: "grid" }}>
            <div className="table-container">
              <Table
                data={tableData}
                columns={tableHeaderData}
                sorter={sorter}
                sortingState={tableSortingState}
              />
            </div>
          </div>
          <br />
          {showPaging && (
            <Paging
              limit20={limit20}
              limit50={limit50}
              limit100={limit100}
              limit={limit}
              setLimit={setLimit}
              offset={offset}
              setOffset={setOffset}
              total={total}
              setPagingParams={setPagingParams}
              resetTableSorting={offState}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
