import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BACKEND_USERS,
  POST,
  VALID_EMAIL_REGEX,
  VALID_GROUP_NAME_REGEX,
} from "../constants";
import useStore from "../store";
import { sessionWrapper } from "../dal/sessionWarpper";
import { postData } from "../dal/fetchRequest";

type CreateUserFormProps = {
  setReload: Function;
  setActive: Function;
};

type CreateUserInputs = {
  name: string;
  email: string;
  cognito_username: string;
  sap_id: string;
};

type CreateData = {
  name: string;
  email: string;
  cognito_username: string;
  sap_id: string;
  user_pool_ID: string | null;
};

export const CreateUserForm = ({
  setReload,
  setActive,
}: CreateUserFormProps) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_USERS}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const userPoolID = useStore((state) => state.userPoolID);
  const resetUserPoolID = useStore((state) => state.resetUserPoolID);
  const resetCompany = useStore((state) => state.resetCompany);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateUserInputs>();

  const onSubmit: SubmitHandler<CreateUserInputs> = async (data) => {
    console.log(data);
    setDisabled(true);

    const userData: CreateData = {
      name: data.name,
      email: data.email,
      cognito_username: data.cognito_username,
      sap_id: data.sap_id,
      user_pool_ID: userPoolID,
    };

    console.log({ userData });

    const response: any = await sessionWrapper(postData, POST, url, userData);
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    setActive(false);
    reset();
    setDisabled(false);
    resetUserPoolID();
    resetCompany();
  };

  return (
    <form id="create-user-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        <label className="label">{t("Name")}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder={t("Add name")}
            {...register("name", { required: true })}
          />
          {errors.name && (
            <p className="has-text-danger">{t("This field is required")}</p>
          )}
        </div>
      </div>

      <div className="field">
        <label className="label">{t("Email")}</label>
        <div className="control">
          <input
            className="input"
            type="email"
            placeholder={t("Add email")}
            {...register("email", {
              required: true,
              pattern: {
                value: VALID_EMAIL_REGEX,
                message: "",
              },
            })}
          />
          {errors.email && (
            <p className="has-text-danger">
              {t("This field must match with pattern", {
                pattern: VALID_EMAIL_REGEX,
              })}
            </p>
          )}
        </div>
      </div>

      <div className="field">
        <label className="label">{t("cognito_username")}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder={t("Add Cognito:username")}
            {...register("cognito_username", {
              required: true,
              pattern: {
                value: VALID_GROUP_NAME_REGEX,
                message: "",
              },
            })}
          />
          {errors.cognito_username && (
            <p className="has-text-danger">
              {t("You cannot use spaces in cognito:username")}
            </p>
          )}
        </div>
      </div>

      <div className="field">
        <label className="label">{t("SAP ID")}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder={t("Add SAP ID")}
            {...register("sap_id")}
          />
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button"
            type="reset"
            onClick={() => {
              setActive(false);
              reset();
              setDisabled(false);
            }}
          >
            {t("Cancel")}
          </button>
        </div>
        <div className="control">
          <button
            className="button is-primary"
            type="submit"
            disabled={disabled}
          >
            {t("Save changes")}
          </button>
        </div>
      </div>
    </form>
  );
};
