import { StoreApi, create } from "zustand";
import zukeeper from "zukeeper";
import { KeyValues } from "../applications/types";

type GlobalCustomOverrides = {
  id: number | null;
  name: string | null;
  app_id: string | null;
  key_values: KeyValues[];
};

interface StoreState {
  error: string | null;
  idAndName: {
    id: number | null;
    name: string | null;
  };
  userPoolID: string | null;
  company: string | null;
  groupName: string | null;
  id: number | null;
  groupId: number | null;
  dropdownActive: boolean;
  globalCustomOverrides: GlobalCustomOverrides;
  setGlobalError: (message: string) => void;
  resetGlobalError: () => void;
  setIdAndName: (id: number, name: string) => void;
  resetIdAndName: () => void;
  setUserPoolID: (userPoolID: string) => void;
  resetUserPoolID: () => void;
  setCompany: (company: string) => void;
  resetCompany: () => void;
  setGroupName: (groupName: string) => void;
  resetGroupName: () => void;
  setId: (id: number) => void;
  resetId: () => void;
  setGroupId: (groupId: number) => void;
  resetGroupId: () => void;
  setDropdownActive: (dropdownActive: boolean) => void;
  setGlobalCustomOverrides: (
    globalCustomOverrides: GlobalCustomOverrides
  ) => void;
  resetGlobalCustomOverrides: () => void;
}

const useStore = create<StoreState>(
  zukeeper((set: (arg0: (state: any) => { error: any }) => any) => ({
    error: null,
    idAndName: { id: null, name: null },
    setGlobalError: (message: string) =>
      set((state) => ({ ...state, error: message })),
    resetGlobalError: () => set((state) => ({ ...state, error: null })),
    setIdAndName: (id: number, name: string) =>
      set((state) => ({ ...state, idAndName: { id, name } })),
    resetIdAndName: () =>
      set((state) => ({ ...state, idAndName: { id: null, name: null } })),
    setUserPoolID: (userPoolID: number) =>
      set((state) => ({ ...state, userPoolID })),
    resetUserPoolID: () => set((state) => ({ ...state, userPoolID: null })),
    setCompany: (company: string) => set((state) => ({ ...state, company })),
    resetCompany: () => set((state) => ({ ...state, company: null })),
    setGroupName: (groupName: string) =>
      set((state) => ({ ...state, groupName })),
    resetGroupName: () => set((state) => ({ ...state, groupName: null })),
    setId: (id: number) => set((state) => ({ ...state, id })),
    resetId: () => set((state) => ({ ...state, id: null })),
    setGroupId: (groupId: number) => set((state) => ({ ...state, groupId })),
    resetGroupId: () => set((state) => ({ ...state, groupId: null })),
    setDropdownActive: (dropdownActive: boolean) =>
      set((state) => ({ ...state, dropdownActive })),
    setGlobalCustomOverrides: (globalCustomOverrides: GlobalCustomOverrides) =>
      set((state) => ({ ...state, globalCustomOverrides })),
    resetGlobalCustomOverrides: () =>
      set((state) => ({
        ...state,
        globalCustomOverrides: {
          id: null,
          name: null,
          app_id: null,
          key_values: [],
        },
      })),
  }))
);

declare global {
  interface Window {
    store: StoreApi<StoreState>;
  }
}

window.store = useStore;

export default useStore;
