import { ERROR_CONNECTION_REFUSED } from "../constants";

interface ApiResponse {
  status?: number;
  error?: string | null;
  name?: string;
  message?: string;
}

export const responseErrors = (responses: ApiResponse[]): string | null => {
  const errors = [];

  for (const response of responses) {
    if (response.status && response.status >= 400) {
      const errorResponse = `${response?.name} ${response?.message} (${response?.status})`;
      errors.push({ error: errorResponse });
    }
    if (response.error) {
      errors.push(response);
    }
    if (response.error === null) {
      errors.push({ error: ERROR_CONNECTION_REFUSED });
    }
  }

  const responseErrors = errors.map((obj) => obj.error).join(", ");

  if (errors.length > 0) return responseErrors;
  else return null;
};
