import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BACKEND_APPS, POST } from "../constants";
import useStore from "../store";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { postData } from "../dal/fetchRequest";
import { CreateKeyValuesForm } from "./createKeyValuesForm";
import { KeyValues } from "./types";
import { ValidateKeyValues } from "./helpers";
import { sessionWrapper } from "../dal/sessionWarpper";

type CreateApplicationFormProps = {
  setReload: Function;
  setActive: Function;
};

type CreateApplicationInputs = {
  name: string;
  app_id: string;
  key_values: KeyValues[];
};

type CreateData = {
  name: string;
  app_id: string;
  key_values?: KeyValues[];
};

export const CreateApplicationForm = ({
  setReload,
  setActive,
}: CreateApplicationFormProps) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND}${BACKEND_APPS}`;
  const setGlobalError = useStore((state) => state.setGlobalError);
  const resetGlobalError = useStore((state) => state.resetGlobalError);
  const methods = useForm<CreateApplicationInputs>({
    defaultValues: {
      name: "",
      app_id: "",
      key_values: [],
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<CreateApplicationInputs> = async (data) => {
    console.log(data);
    setDisabled(true);

    const applicationData: CreateData = {
      name: data.name,
      app_id: data.app_id,
      key_values: ValidateKeyValues(data.key_values),
    };

    console.log({ applicationData });

    const response: any = await sessionWrapper(
      postData,
      POST,
      url,
      applicationData
    );
    console.log({ response });
    if (response.data) {
      resetGlobalError();
      setReload(true);
    } else {
      setGlobalError(response.error);
    }
    setActive(false);
    reset();
    setDisabled(false);
  };

  return (
    <FormProvider {...methods}>
      <form id="create-application-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label className="label">{t("Application name")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add application name")}
              {...register("name", { required: true })}
            />
            {errors.name && (
              <p className="has-text-danger">{t("This field is required")}</p>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Application ID")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("Add application ID")}
              {...register("app_id", { required: true })}
            />
            {errors.app_id && (
              <p className="has-text-danger">{t("This field is required")}</p>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label">{t("Default key values")}</label>
          <div className="control">
            <CreateKeyValuesForm />
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              className="button"
              type="reset"
              onClick={() => {
                setActive(false);
                reset();
                setDisabled(false);
              }}
            >
              {t("Cancel")}
            </button>
          </div>
          <div className="control">
            <button
              className="button is-primary"
              type="submit"
              disabled={disabled}
            >
              {t("Save changes")}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
