import { useTranslation } from "react-i18next";

interface CreateButtonProps {
  callback: Function;
}

export const CreateButton: React.FC<CreateButtonProps> = ({ callback }) => {
  const { t } = useTranslation();

  return (
    <button
      className="button is-primary is-large is-pulled-right mt-3 mr-3"
      type="button"
      onClick={() => callback()}
    >
      {t("Create")}
    </button>
  );
};
